export const GET_POSTING_DATES = "GET_POSTING_DATES";
export const GET_POSTING_DATES_SUCCESS = "GET_POSTING_DATES_SUCCESS";
export const GET_POSTING_DATES_FAIL = "GET_POSTING_DATES_FAIL";

export const SAVE_POSTING_DATES="SAVE_POSTING_DATES";
export const SAVE_POSTING_DATES_SUCCESS="SAVE_POSTING_DATES_SUCCESS";
export const SAVE_POSTING_DATES_FAILURE="SAVE_POSTING_DATES_FAILURE";

export const EDIT_DISPLAY_YEAR="EDIT_DISPLAY_YEAR";
export const EDIT_DISPLAY_YEAR_SUCCESS="EDIT_DISPLAY_YEAR_SUCCESS";
export const EDIT_DISPLAY_YEAR_FAIL="EDIT_DISPLAY_YEAR_FAIL";
