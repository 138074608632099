import DataTable, { createTheme } from 'react-data-table-component';
import React, { useMemo } from 'react';
import { Row, Col, Input, InputGroup, Button, Form, FormGroup, Label } from 'reactstrap';
import { Card, CardHeader, Alert, CardBody } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatchResult } from '../store/events/actions';
import { useAuth } from '../hooks/useAuth';
import { downloadStandardCertificate, downloadStandardLetter, sendStandardCertificate, sendStandardLetter, batchPrint } from '../store/events/actions';
import { Circles } from "react-loader-spinner";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Overlay, Popover } from 'react-bootstrap';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ContentLoader from "react-content-loader";

export const BatchResultDisplay = () => {
  const { user, logout } = useAuth();
  const { batchRankingList, loadingBatchResult, certificateData, loadingDownloadCertificate, loadingSendCertificate, loadingDownloadLetter, letterId, loadingSendLetter, batchDetails, loadingBatchPrint } = useSelector((state) => state.EventsReducer);
  const [batchDogData, setBatchDogData] = useState([]);
  const [newLoading, setNewLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [iframeKey, setIframeKey] = useState(0);
  const [currentCertificate, setCurrentCertificate] = useState([{}]);
  const [letterIframeVisible, setLetterIframeVisible] = useState(false);
  const [letterUrl, setLetterUrl] = useState('');
  const [email, setEmail] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [currentBatchData, setCurrentBatchData] = useState([]);
  const [batchIframeVisible, setBatchIframeVisible] = useState(false);
  const [batchDocUrl, setBatchDocUrl] = useState('');
  const [iframeIndex, setIframeIndex] = useState(1);
  const [countOfBatches, setCountOfBatches] = useState(1);
  const [printCategory, setPrintCategory] = useState('');

  let dispatch = useDispatch();

  // Function to extract values from the query string
  const getQueryParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      eventType: urlParams.get('eventType'),
      // categoryType: urlParams.get('categoryType'),
      // breedGroup: urlParams.get('breedGroup'),
      eventYear: urlParams.get('eventYear'),
      corResult: urlParams.get('corResult'),
    };
  };

  // Get values from the query string
  const { eventType, eventYear, corResult } = getQueryParams();

  useEffect(() => {
    setNewLoading(true);
    dispatch(getBatchResult({ eventType, eventYear, corResult }, user.token));
  }, [dispatch, eventType, eventYear, corResult]);


  useEffect(() => {
    // console.log('batchData', batchRankingList);
    for (let key in batchRankingList) {
      Object.entries(batchRankingList[key]).forEach(([dataIndex, dataArray]) => {
        const updatedArray = dataArray.map((item, index) => {
          const { dog_title: dogTitle, result_id: resultId, start_date: startYear, ...rest } = item; // Fetch dog_title and rename it to dogTitle
          const updatedStartYear = (new Date(startYear)).getFullYear(); // Update startYear
          return { ...rest, dogTitle, resultId, startYear: updatedStartYear, _parentIndex: dataIndex, _mainIndex: index }; // Include renamed key in the returned object
        });
        setBatchDogData(prevData => [...prevData, { key: key, dataIndex: dataIndex, dataArray: updatedArray }]);
        setCurrentBatchData(prevData => [...prevData, { key: key, dataIndex: dataIndex, dataArray: updatedArray }]);
      });
    }
    setNewLoading(false);
  }, [batchRankingList]);

  let generateCertificate = async (row) => {
    // console.log('key',row._mainIndex,row._parentIndex);
    // console.log(corResult);
    let corValue = 0;
    if(parseInt(corResult) === 1){
      corValue = 1;
    }
    let certificateTitle = '';
    switch (eventType) {
      case "Agility":
        certificateTitle = "CKC TOP " + eventType.toUpperCase() + " DOG";
        break;
      case "Beagle":
        certificateTitle = "CKC TOP FIELD BEAGLE";
        break;
      case "Herding":
        certificateTitle = "CKC TOP " + eventType.toUpperCase() + " DOG";
        break;
      case "Lure Coursing":
        certificateTitle = "CKC TOP " + eventType.toUpperCase() + " DOG";
        break;
      case "Pointing - Field Trial":
        certificateTitle = "CKC TOP POINTING DOG";
        break;
      case "Retriever Field Trial":
        certificateTitle = "CKC TOP RETRIEVING DOG";
        break;
      case "Spaniel Field Trial":
        certificateTitle = "CKC TOP FIELD SPANIEL";
        break;
      case "Conformation":
        certificateTitle = "CKC TOP SHOW DOG";
        break;
      case "Obedience":
        certificateTitle = "CKC TOP " + eventType.toUpperCase() + " DOG";
        break;
      case "Rally Obedience":
        certificateTitle = "CKC TOP " + eventType.toUpperCase() + " DOG";
        break;
      default:
        certificateTitle = "CKC TOP " + eventType.toUpperCase() + " DOG";
        break;
    }
    let certificateYear = '';
    if(row.startYear != ''){
      certificateYear = row.startYear
    }
    else{
      certificateYear = eventYear;
    }
    const data = {
      mainIndex: row._mainIndex,
      eventType: eventType,
      breedGroup: row._parentIndex,
      startYear: certificateYear,
      resultId: row.resultId,
      ranking: row.new_rank,
      dogTitle: row.dogTitle,
      dogName: row.dog_name,
      certificateId: '',
      certificateTitle: certificateTitle,
      corResult: corValue,
    }
    // console.log(data);
    dispatch(downloadStandardCertificate(data, user.token));
    setCurrentCertificate([data]);
    setSelectedRow(row);
    setIframeVisible(true);
  }

  const onFormChange = (key, value) => {
    currentCertificate[key] = value;
    setCurrentCertificate([currentCertificate]);
  }

  const updateCertificate = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const updatedCertificateData = {
      eventType: eventType,
      breedGroup: data.get("breedGroup"),
      certificateTitle: data.get("certificateTitle"),
      dogTitle: data.get("dogTitle"),
      ranking: data.get("dogRank"),
      resultId: data.get("resultId"),
      startYear: data.get("startYear"),
      certificateId: certificateData.certificateId
    }
    dispatch(downloadStandardCertificate(updatedCertificateData, user.token));
    // Find the index of the certificate in the array
    const parentIndex = data.get("breedGroup");
    const mainIndex = data.get("mainIndex");
    //console.log('currentBatchData', currentBatchData);
    const updatedBatchData = currentBatchData.map(breed => {
      if (breed.dataArray != '' && breed.dataIndex == parentIndex) {
        breed.dataArray[mainIndex]['certificateTitle'] = data.get("certificateTitle");
        breed.dataArray[mainIndex]['dogTitle'] = data.get("dogTitle");
        breed.dataArray[mainIndex]['ranking'] = data.get("dogRank");
        breed.dataArray[mainIndex]['startYear'] = data.get("startYear");
      }
      return breed;
    });

    // Update the state with the new array
    //console.log('updatedData', updatedBatchData);
    setCurrentBatchData(updatedBatchData);
  };

  useEffect(() => {
    const appUrl = process.env.REACT_APP_APP_URL;
    const pdfUrl = appUrl+'getCertificate/';
    if (certificateData) {
      const url = pdfUrl + certificateData.certificateId;
      setCertificateUrl(url);
    }
    if(!loadingDownloadCertificate){
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [certificateData,loadingDownloadCertificate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  //Send Certificate to Owner
  const sendCertificate = async (event) => {
    event.preventDefault();
    const certificateSendData = {
      email: email,
      certificateId: certificateData.certificateId
    }
    dispatch(sendStandardCertificate(certificateSendData, user.token));
  }

  const generateLetter = async (row)=>{
    let corValue = 0;
    if(parseInt(corResult) === 1){
      corValue = 1;
    }
    const data = {
      resultId: row.resultId,
      corResult: corValue,
    }
    dispatch(downloadStandardLetter(data,user.token));
    setLetterIframeVisible(true);
    setEmail('');
  }

  const sendLetter = async (event) => {
    event.preventDefault();
    const letterData = {
      email: email,
      letterId: letterId
    }
    dispatch(sendStandardLetter(letterData, user.token));
  }

  useEffect(() => {
    const appUrl = process.env.REACT_APP_APP_URL;
    const pdfUrl = appUrl+'getLetter/';
    if (letterId) {
      const url = pdfUrl + letterId;
      setLetterUrl(url);
    }
    if(!loadingDownloadLetter){
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [letterId,loadingDownloadLetter]);

  //Close the Modal
  const closeModal = () => {
    setIframeVisible(false);
    setLetterIframeVisible(false);
    setBatchIframeVisible(false);
  };

  const handleButtonClick = (event) => {
    setShowPopover(!showPopover);
    setTarget(event.target);
  };

  useEffect(() => {
    const appUrl = process.env.REACT_APP_APP_URL;
    const pdfUrl = appUrl+'getBatchFile/';
    if (batchDetails) {
      // console.log('iframeIndex',iframeIndex);
      const batchId = batchDetails.batchId;
      const batchType = batchDetails.batchType;
      const eventName = batchDetails.eventName;
      const printDate = batchDetails.currDate;
      const numOfBatches = batchDetails.numOfBatches.length;
      setCountOfBatches(numOfBatches);
      // console.log('numOfBatches',numOfBatches);
      if(batchType == 'certificate'){
        const url = pdfUrl + 'certificate/' + eventName + '/' + printDate + '/' + iframeIndex;
        setBatchDocUrl(url);
      } else{
        const url = pdfUrl + 'letter/' + eventName + '/' + printDate + '/' + iframeIndex;
        setBatchDocUrl(url);
      }
    }
    if(!loadingBatchPrint || iframeIndex){
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [batchDetails,loadingBatchPrint,iframeIndex]);

  const printBatchOption = async (category)=>{
    let corValue = 0;
    if(parseInt(corResult) === 1){
      corValue = 1;
    }
    const batchPrintData = {
      printType: category,
      eventType: eventType,
      //breedGroup: breedGroup,
      batchData: currentBatchData,
      corResult: corValue
    }
    dispatch(batchPrint(batchPrintData, user.token));
    setPrintCategory(category);
    setShowPopover(!showPopover);
    setBatchIframeVisible(true);
    setIframeIndex(1);
  }

  const handleNextButtonClick = async (currentIndex) => {
    // console.log('NextIndex',currentIndex);
    if(iframeIndex < currentIndex) {
      setIframeIndex(iframeIndex + 1);
    } else {
      alert("No more content available");
    }
  }

  const handlePreviousButtonClick = async (currentIndex) => {
    console.log('previousIndex',currentIndex);
    if(iframeIndex > currentIndex) {
      setIframeIndex(iframeIndex - 1);
    } else {
      alert("No more content available");
    }
  }

  const formatDogName = (row) => {
    let string = row.dogTitle;
    let arrayOfString = string.includes(" ") ? string.split(" ") : [string];
    let characterLength = 0;
    let lineIndex = 0;
    let line = [];
    let totalValues = arrayOfString.length;
    let formattedText = '';
    if (totalValues > 1) {
      arrayOfString.forEach((value, key) => {
          let valCharLength = value.length + 1; // add 1 for the space after each word
          characterLength += valCharLength;
          
          if (characterLength <= 31) {
              line[lineIndex] = line[lineIndex] || [];
              line[lineIndex].push(value);
          } else {
              lineIndex++;
              if (key === totalValues - 1) { // if it's the last element in the array
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(line[lineIndex - 1].pop()); // Get element from last line
                  line[lineIndex].push(value);
              } else {
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(value);
              }
              characterLength = valCharLength; // reset character length for the new line
          }
      });
      
      // Join lines with <br> tag
      formattedText = line.map(line => line.join(' ')).join("\n");
    }
    else{
      formattedText = arrayOfString;
    }
    return formattedText;
  };

  const columnsAgility = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>
      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
      grow: 2
    },
    {
      name: 'Av. Score',
      selector: row => row.average_score,
      center: true,
      wrap: true,
    },
    {
      id: "totalPoints",
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="10">       
          <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={()=>generateCertificate(row)}><CardMembershipIcon/></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },

  ], []);

  const columnsBeagle = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>
      ),
    },
    {
      name: 'Class/Stake',
      selector: row => row.class,
      wrap: true,
      grow: 2
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
      grow: 2
    },
    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
    {
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="10">       
            <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={()=>generateCertificate(row)}><CardMembershipIcon/></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },

  ], []);

  const columnsLureCoursing = useMemo(() => [

    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
      grow: 2
    },

    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
 
    {
      id: "totalPoints",
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="10">       
            <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={()=>generateCertificate(row)}><CardMembershipIcon/></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },

  ], []);

  const columnsObedience = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>
      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
    },
    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
    {
      name: 'Av. Score',
      selector: row => row.average_score,
      center: true,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={() => generateCertificate(row)}>
              <CardMembershipIcon/>
            </Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },
  ], []);

  const columnsConformation = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
    },
    {
      name: 'Total Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
      grow: 2
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="10">          
            <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={()=>generateCertificate(row)}><CardMembershipIcon/></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },

  ], []);

  function calculateRank(results_array) {
    // Sort the array based on score
    results_array.sort((a, b) => b.total_points - a.total_points);

    let rank = 1;
    let lastScore = null;

    // Assign a rank to each object based on its position in the sorted array
    results_array.forEach((item, index) => {
      if (item.total_points !== lastScore) {
        rank = index + 1;
      }
      item.new_rank = rank;
      lastScore = item.total_points;
    });
    return results_array;
  }

  return (
    <div>
      <div style={{ backgroundColor: '#F1F2F4' }}>
      <Modal isOpen={iframeVisible} toggle={() => setIframeVisible(false)} size="xl">
          <ModalHeader toggle={closeModal}>Certificate for Dog Show</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="8">
                {loadingDownloadCertificate ? (
                  <div className="loader" >
                    <ContentLoader 
                      speed={1}
                      style={{ width: '100%', height: '100%' }}
                      viewBox="0 0 700 500"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#cecccc"
                    >
                      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                    </ContentLoader>
                  </div>
                ) : (
                  <iframe
                    key={iframeKey}
                    title="Certificate"
                    src={certificateUrl}
                    width="100%"
                    height="600px"
                    allowFullScreen>
                  </iframe>
                )}
              </Col>
              <Col md="4">
                <div>
                  <h5><ModeEditIcon/> Edit Certificate</h5>
                  <hr></hr>
                  <Form onSubmit={updateCertificate}>
                    <FormGroup row>
                      <Input type="hidden" name="resultId" value={currentCertificate[0]?.resultId} />
                      <Input type="hidden" name="mainIndex" value={currentCertificate[0]?.mainIndex} />
                      <Input type="hidden" name="breedGroup" value={currentCertificate[0]?.breedGroup} />
                    </FormGroup>
                    <FormGroup row>
                      <Label for="certificateTitle" sm={4}> Certificate Title </Label>
                      <Col sm={7}>
                        <Input id="certificateTitle" name="certificateTitle" value={currentCertificate[0]?.certificateTitle} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Certificate Title" type="text" pattern="[^\d]+" title="Only letters are allowed" required/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="dogTitle" sm={4}> Dog Titles </Label>
                      <Col sm={7}>
                        <Input id="dogTitle" name="dogTitle" value={currentCertificate[0]?.dogTitle} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Dog Title" type="text" required/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="dogRank" sm={4}> Dog Rank </Label>
                      <Col sm={7}>
                        <Input id="dogRank" name="dogRank" value={currentCertificate[0]?.ranking} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Dog Rank" type="number" pattern="[0-9]*" title="Only numbers are allowed" required/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="startYear" sm={4}> Event Year </Label>
                      <Col sm={7}>
                        <Input id="startYear" name="startYear" value={currentCertificate[0]?.startYear} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Year" type="number" pattern="[0-9]*" title="Only numbers are allowed" required/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={{ offset: 4, size: 6}}>
                        <Button color='primary' outline type='submit'>
                          {loadingDownloadCertificate ? ('Please wait...') : ('Update')}
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <hr></hr>
                <div>
                  <Form onSubmit={sendCertificate}>
                    <FormGroup row>
                      <Label for="email" sm={4}> Enter Email</Label>
                      <Col sm={7}>
                        <Input id="email" name="email" placeholder="Enter Email" type="email" value={email} onChange={handleEmailChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address" required/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={{ offset: 4, size: 6}}>
                        <Button color='primary' className='marginRight' outline type='submit'>
                          {loadingSendCertificate ? ('Sending...') : ('Send Certificate')}
                        </Button>
                        </Col>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={letterIframeVisible} toggle={() => setLetterIframeVisible(false)} size="xl">
          <ModalHeader toggle={closeModal}>Letter</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="8">
                {loadingDownloadLetter ? (
                  <div className="loader" >
                    <ContentLoader 
                      speed={1}
                      style={{ width: '100%', height: '100%' }}
                      viewBox="0 0 700 500"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#cecccc"
                    >
                      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                    </ContentLoader>
                  </div>
                ) : (
                <iframe
                  key={iframeKey}
                  title="Letter"
                  src={letterUrl}
                  width="100%"
                  height="600px"
                  allowFullScreen
                ></iframe>
                )}
              </Col>
              <Col md="4">
                <hr></hr>
                <div>
                  <Form onSubmit={sendLetter}>
                    <FormGroup row>
                      <Label for="email" sm={4}> Enter Email</Label>
                      <Col sm={7}>
                        <Input id="email" name="email" placeholder="Enter Email" type="email" value={email} onChange={handleEmailChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address" required/>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={{ offset: 4, size: 6}}>
                        <Button color='primary' className='marginRight' outline type='submit'>
                          {loadingSendLetter ? ('Sending...') : ('Send Letter')}
                        </Button>
                        </Col>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={batchIframeVisible} toggle={() => setBatchIframeVisible(false)} size="xl">
          <ModalHeader toggle={closeModal}>
            <span>Batch Print - {printCategory}</span>
            <span className='span-right'>{loadingBatchPrint ? '' : 'Showing ' + iframeIndex + ' of ' + countOfBatches}</span>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                {loadingBatchPrint ? (
                  <div className="loader" >
                    <ContentLoader 
                      speed={1}
                      style={{ width: '100%', height: '100%' }}
                      viewBox="0 0 1100 600"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#cecccc"
                    >
                      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                    </ContentLoader>
                  </div>
                ) : (
                <>
                  <iframe
                    key={iframeKey}
                    title="Batch Document"
                    src={batchDocUrl}
                    width="100%"
                    height="600px"
                    allowFullScreen
                  ></iframe>
                  <div className='right-btn'>
                    {(iframeIndex > 1 && iframeIndex <= countOfBatches) ? (
                      <Button className= "marginRight" color="primary" onClick={() => handlePreviousButtonClick(iframeIndex - 1)}>
                        <KeyboardDoubleArrowLeftIcon/> Previous
                      </Button>
                    ) : (countOfBatches > 1 ? 
                      <Button className= "marginRight" color="secondary">
                        <KeyboardDoubleArrowLeftIcon/> Previous
                      </Button> : '')
                    }
                    {(countOfBatches > 1 && iframeIndex < countOfBatches) ? (
                      <Button color="primary" onClick={() => handleNextButtonClick(iframeIndex + 1)}>
                        Next <KeyboardDoubleArrowRightIcon/>
                      </Button>
                    ) : (countOfBatches > 1 ? 
                      <Button color="secondary">
                        Next <KeyboardDoubleArrowRightIcon/>
                      </Button> : '')
                    }
                  </div>
                </>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <p className='page-heading'>Batch Results For {eventYear} - "{eventType}"
          <span className="right-btn">
            <Button color="primary" onClick={handleButtonClick}>Print Batch
            </Button>
            <Overlay show={showPopover} target={target} placement="left" rootClose onToggle={(newShow) => setShowPopover(newShow)}>
              <Popover id="popover-container">
                <Popover.Header as="h3">Select Print Option</Popover.Header>
                <Popover.Body>
                  <Button color='primary' className='marginRight' outline onClick={()=>printBatchOption('Certificate')}>
                    Certificate
                  </Button>
                  <Button color='primary' className='marginRight' outline onClick={()=>printBatchOption('Letter')}>
                    Letter
                  </Button>
                </Popover.Body>
              </Popover>
            </Overlay>
          </span>
        </p>
      </div>
      <div className='page-body'>
        {loadingBatchResult ? (
          <div className="loader">
            <ContentLoader 
              speed={1}
              width={'100%'}
              height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
              viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {Array.from({ length: 10 }, (_, index) => (
                <rect
                  key={index}
                  x="0"
                  y={(70 + 4) * index}
                  rx="5"
                  ry="5"
                  width="100%"
                  height="70"
                />
              ))}
            </ContentLoader>
          </div>
        ) : (
          batchDogData.filter(breed => breed.dataArray !== '').map((breed, index) => (
            <Card className="my-2" style={{ padding: 0 }} key={index}>
              <CardBody className='event-card-body'>
                <div>
                  <CardHeader><strong>{breed.key === "BreedsData" || breed.key === "GroupResult" || breed.key === "ClassResult" || eventType === "Lure Coursing" || eventType === "Herding"  || breed.dataIndex === "Mixed Breed" ? "Top 10" : "Top 5"} - {(breed.key === "GroupResult" ? breed.dataIndex + ' Group' : eventType === "Retriever Field Trial" ? breed.dataIndex + ' Retriever' : breed.dataIndex)}</strong></CardHeader>
                  <DataTable
                    columns={eventType === "Beagle" || eventType === "Pointing - Field Trial" || eventType === "Retriever Field Trial" || eventType === "Spaniel Field Trial" ? (columnsBeagle) : eventType === "Lure Coursing" ? (columnsLureCoursing) : eventType === "Obedience" || eventType === "Rally Obedience" ? (columnsObedience) : eventType === "Conformation" ? (columnsConformation) : (columnsAgility)}
                    data={calculateRank(breed.dataArray)}
                    filter
                    theme=""
                    progressPending={newLoading ? newLoading : loadingBatchResult}
                    highlightOnHover
                    defaultSortAsc={false}
                    defaultSortFieldId={"totalPoints"}
                  />
                </div>
              </CardBody>
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

export default BatchResultDisplay;
