import {
  GET_DOGS,
  GET_DOGS_SUCCESS,
  GET_DOGS_FAIL,
  DEL_DOG,
  DEL_DOG_FAILURE,
  DEL_DOG_SUCCESS,
  EDIT_DOG,
  EDIT_DOG_SUCCESS,
  EDIT_DOG_FAILURE,
  GET_BREED_GROUP,
  GET_BREED_GROUP_SUCCESS,
  GET_BREED_GROUP_FAIL,
  ADD_BREED_GROUP,
  ADD_BREED_GROUP_SUCCESS,
  ADD_BREED_GROUP_FAIL,
  EDIT_BREED_GROUP,
  EDIT_BREED_GROUP_SUCCESS,
  EDIT_BREED_GROUP_FAIL,
  DEL_BREED_GROUP,
  DEL_BREED_GROUP_SUCCESS,
  DEL_BREED_GROUP_FAIL,
  UPLOAD_GROUP_FILE,
  UPLOAD_GROUP_FILE_SUCCESS,
  UPLOAD_GROUP_FILE_FAIL
} from "./actionTypes";

export const getDogs= (token) => {
  return {
    type: GET_DOGS,
    token:token
  };
};

export const getDogsSuccess = (dogList) => {
  return {
    type: GET_DOGS_SUCCESS,
    payload: dogList,
  };
};

export const getDogsFail = (error) => {
  return {
    type:GET_DOGS_FAIL,
    payload: error,
  };
};

export const delDog = (data,navigate,token) => {
  return {
    type: DEL_DOG,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delDogSuccess = (delDogResponse) => {
  return {
    type: DEL_DOG_SUCCESS,
    payload:delDogResponse,
  };
};

export const delDogFailure = (error) => {
  return {
    type: DEL_DOG_FAILURE,
    payload:error,
  };
};

export const editDog = (data,token,navigate) => {
  return {
    type: EDIT_DOG,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const editDogSuccess = (editDogResponse) => {
  return {
    type: EDIT_DOG_SUCCESS,
    payload:editDogResponse,
  };
};

export const editDogFailure = (error) => {
  return {
    type: EDIT_DOG_FAILURE,
    payload:error,
  };
};

export const getBreedGroups= (year, token) => {
  return {
    type: GET_BREED_GROUP,
    payload: year,
    token: token
  };
};

export const getBreedGroupsSuccess = (groupList) => {
  return {
    type: GET_BREED_GROUP_SUCCESS,
    payload: groupList,
  };
};

export const getBreedGroupsFail = (error) => {
  return {
    type: GET_BREED_GROUP_FAIL,
    payload: error,
  };
};

export const addBreedGroup = (data,token,navigate) => {
  return {
    type: ADD_BREED_GROUP,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const addBreedGroupSuccess = (breedData) => {
  return {
    type: ADD_BREED_GROUP_SUCCESS,
    payload: breedData,
  };
};

export const addBreedGroupFail = (error) => {
  return {
    type: ADD_BREED_GROUP_FAIL,
    payload: error,
  };
};

export const editBreedGroup = (data,token,navigate) => {
  return {
    type: EDIT_BREED_GROUP,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const editBreedGroupSuccess = (breedData) => {
  return {
    type: EDIT_BREED_GROUP_SUCCESS,
    payload: breedData,
  };
};

export const editBreedGroupFail = (error) => {
  return {
    type: EDIT_BREED_GROUP_FAIL,
    payload: error,
  };
};

export const delBreedGroup = (data,navigate,token) => {
  return {
    type: DEL_BREED_GROUP,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delBreedGroupSuccess = (delBreedGroupResponse) => {
  return {
    type: DEL_BREED_GROUP_SUCCESS,
    payload:delBreedGroupResponse,
  };
};

export const delBreedGroupFailure = (error) => {
  return {
    type: DEL_BREED_GROUP_FAIL,
    payload:error,
  };
};

export const uploadGroupFile = (data,navigate,token) => {
  return {
    type: UPLOAD_GROUP_FILE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const uploadGroupFileSuccess = (delBreedGroupResponse) => {
  return {
    type: UPLOAD_GROUP_FILE_SUCCESS,
    payload:delBreedGroupResponse,
  };
};

export const uploadGroupFileFailure = (error) => {
  return {
    type: UPLOAD_GROUP_FILE_FAIL,
    payload:error,
  };
};

