import { Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Col, Card, CardBody } from 'reactstrap';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getPostingDates, editDisplayYear } from '../store/postingDates/actions';
import "../styles.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/useAuth';
import ContentLoader from "react-content-loader";
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";

function PublicCertificates() {

  const { user } = useAuth();
  const { postingDatesList, loading} = useSelector((state) => state.PostingDatesReducer);
  const [toggleStates, setToggleStates] = useState([]);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPostingDates(user?user.token:""));
  }, []);

  useEffect(() => {
    const initialToggleStates = postingDatesList.reduce((data, row) => {
      data[row._id] = row.display !== undefined ? row.display : false;
      return data;
    }, {});
    setToggleStates(initialToggleStates);
  }, [postingDatesList]);

  const switchToggle = (id, value) => {
    setToggleStates(prevStates => ({
      ...prevStates,
      [id]: value,
    }));
    const data = {
      yearId: id,
      displayValue: value
    }
    dispatch(editDisplayYear(data, navigate, user.token));
  };

  const columns = useMemo(() => [
    {
      name: 'Certificate Year',
      sortable: true,
      wrap: true,
      cell: row => row.year
    },
    {
      name: 'Display Certificates',
      ignoreRowClick: true,
      grow: 1,
      cell: row => (
      <Row>
        <Col>
          <Switch onChange={(checked) => switchToggle(row._id, checked)} checked={toggleStates[row._id]} height={20} width={45}/>
        </Col>
      </Row>
      ),
    },
  ], [toggleStates]);

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Manage Digital Certificates</p>     
      <ToastContainer />
      <div className='page-body'>
        <Card className="my-2" style={{ padding: 0 }}>
          <CardBody className='hotel-locations-card-body'>
            {loading ? (
            <div className="loader" >
              <ContentLoader 
                speed={1}
                width={'100%'}
                height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                {Array.from({ length: 10 }, (_, index) => (
                  <rect
                    key={index}
                    x="0"
                    y={(70 + 4) * index}
                    rx="5"
                    ry="5"
                    width="100%"
                    height="70"
                  />
                ))}
              </ContentLoader>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={postingDatesList}
              pagination
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              highlightOnHover
              fixedHeader
              sortIcon={<FilterListIcon />}
            />
          )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default PublicCertificates;
