import { Button } from 'reactstrap';
import React from 'react';
import { Table } from 'reactstrap';
import { Card,CardBody,CardTitle,CardText,CardSubtitle } from 'reactstrap';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import logo from '../assets/expired.png';

function ErrorPage() {
  const { loginResponse, loading } = useSelector((state) => state.LoginReducer);
  const { user ,logout} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let interval = setInterval(()=>{logout()}, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ backgroundColor: '#F1F2F4', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div>
        <div className='page-body' style={{ textAlign: 'center' }}>
          <img src={logo} alt="Error" style={{ width: '500px', height: '500px', marginBottom: '20px' }} />
          <Card style={{ textAlign: 'center', width: '30rem' }}>
            <CardBody>
              <CardTitle tag="h5">
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Session Expired. Redirecting to Login Page in 5 Seconds.
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
