import React, { useMemo } from "react";

export function ResultColumns(event_category) {
  console.log(event_category + " columns");

  const breedWithSizeVariety = (breed, size, variety, ccn_breed) => {
    let new_breed;

    if (breed === "Unrecognized breed") {
      new_breed = ccn_breed;
    } else if (
      breed === "Belgian Shepherd Dog" ||
      breed === "Chinese Crested Dog"
    ) {
      new_breed = breed;
    } else {
      new_breed =
        breed +
        (size ? " (" + size + ")" : "") +
        (variety ? " (" + variety + ")" : "");
    }
    return new_breed;
  };

  const agilityColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },

      {
        name: "Level",
        selector: (row) => row.level,
        sortable: true,

        wrap: true,
      },
      {
        name: "Division",
        selector: (row) => row.division,
        sortable: true,
        wrap: true,
      },

      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Score1",
        selector: (row) => row.score,
        wrap: true,
      },
      {
        name: "Judge",
        selector: (row) => row.judge,
        wrap: true,
      },
    ],
    []
  );
  const beagleColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },

      {
        name: "Placement",
        selector: (row) => row.placement,
        sortable: true,

        wrap: true,
      },

      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Points",
        selector: (row) => row.score,
        wrap: true,
      },
      {
        name: "Judge A",
        selector: (row) => row.judge_A,
        wrap: true,
      },
      {
        name: "Judge B",
        selector: (row) => row.judge_B,
        wrap: true,
      },
    ],
    []
  );

  const chaseAbilityColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },

      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Pass or Fail",
        selector: (row) => row.result,
        wrap: true,
      },
      {
        name: "Judge",
        selector: (row) => row.judge,
        wrap: true,
      },
    ],
    []
  );

  const draftDogColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Pass or Fail A",
        selector: (row) => row.result_A,
        wrap: true,
      },
      {
        name: "Pass or Fail B",
        selector: (row) => row.result_B,
        wrap: true,
      },
      {
        name: "Pass or Fail C",
        selector: (row) => row.result_C,
        wrap: true,
      },
      {
        name: "Judge A",
        selector: (row) => row.judge_A,
        wrap: true,
      },
      {
        name: "Judge B",
        selector: (row) => row.judge_B,
        wrap: true,
      },
      {
        name: "Judge C",
        selector: (row) => row.judge_C,
        wrap: true,
      },
    ],
    []
  );
  const pointingFieldTestColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },

      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Score 1",
        selector: (row) => row.score,
        wrap: true,
      },
      {
        name: "Judge",
        selector: (row) => row.judge,
        wrap: true,
      },
    ],
    []
  );

  const pointingFieldTrailColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },

    
      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Placement",
        selector: (row) => row.placement,
        sortable: true,

        wrap: true,
      },

      {
        name: "Points",
        selector: (row) => row.score,
        wrap: true,
      },
      {
        name: "Judge A",
        selector: (row) => row.judge_A,
        wrap: true,
      },
      {
        name: "Judge B",
        selector: (row) => row.judge_B,
        wrap: true,
      },
    ],
    []
  );
  const retrieverHuntColumns = useMemo(
    () => [
      {
        name: "Class or Stake",
        selector: (row) => row.class,
        sortable: true,
        wrap: true,
        grow: 2,
      },

      {
        name: "Dog Name with Title",
        selector: (row) => row.dog_data.dog_title,
        wrap: true,
        grow: 3,
      },

      {
        name: "Breed",
        selector: (row) =>
          breedWithSizeVariety(
            row.dog_data.breed,
            row.dog_data.breed_size,
            row.dog_data.breed_variety,
            row.dog_data.ccn_breed
          ),
        wrap: true,
        grow: 2,
      },

      {
        name: "Full Name (Contact)",
        selector: (row) => row.contact_data.name,
        wrap: true,
        grow: 2,
      },
      {
        name: "Pass or Fail",
        selector: (row) => row.result,
        wrap: true,
      },
      {
        name: "Judge A",
        selector: (row) => row.judge_A,
        wrap: true,
      },
      {
        name: "Judge B",
        selector: (row) => row.judge_B,
        wrap: true,
      }
    ],
    []
  );
  let columns;
  switch (event_category) {
    case "Agility":
      columns = agilityColumns;
      break;
    case "Beagle":
      columns = beagleColumns;

      break;

    case "Chase Ability":
      columns = chaseAbilityColumns;

      break;

    case "Draft Dog Test":
      columns = draftDogColumns;

      break;

    case "Earth Dog Test":
      columns = chaseAbilityColumns;

      break;

    case "Herding":
      break;

    case "Lure Coursing":
      break;

    case "Pointing Field Test":
      columns = pointingFieldTestColumns;

      break;

    case "Pointing - Field Trial":
      columns = pointingFieldTrailColumns;

      break;

    case "Pointing Water Test":
      columns = chaseAbilityColumns;

      break;

    case "Retriever Field Trial":
      columns=pointingFieldTrailColumns;
      break;

    case "Retriever Hunt Test":
      columns = retrieverHuntColumns;

      break;

    case "RetrieverHunt-WorkingCertTest":
      columns = retrieverHuntColumns;

      break;

    case "Spaniel Field Trial":
      columns=pointingFieldTrailColumns;
      break;

    case "Spaniel Hunt Test":
      columns = retrieverHuntColumns;

      break;

    case "Sprinter Test":
      columns=pointingFieldTestColumns;
      break;

    case "Tracking Test":
      columns=chaseAbilityColumns;
      break;

    case "Scent Detection":
      break;

    default:
      break;
  }
  return columns;
}
