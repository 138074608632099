import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import { Col,Card,CardHeader,CardBody,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import Grid from "@mui/material/Grid";

import {Routes, Route, useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import { getEventList } from '../store/events/actions';
import ContentLoader from "react-content-loader";


function EventList() {

  const { user ,logout} = useAuth();
  const location = useLocation();
  const eventFileId=location.state.eventFileId;
  const { fileEventList, loadingGetFileEventList } = useSelector((state) => state.EventsReducer);

  const navigate = useNavigate();

  const viewEventAttendee=(index,eventId,event)=>{
    navigate('/dashboard/event-attendees',{state:{index:index,eventId:eventId,event:event}});

  }
  const viewEventResult=(index,eventId,event)=>{
    navigate('/dashboard/event-result',{state:{index:index,eventId:eventId,event:event}});

  }

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventList(eventFileId,user.token));

  }, []);

  const columns = useMemo(()=>[
    {
      name: 'Event Number',
      selector: row => row.event_number,
      sortable: true,
      wrap:true,
      grow:2

    },
    {
      name: 'Event Club',
      selector: row => row.club,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Event Type',
      selector: row => row.event_type,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Start Date',
      selector: row => row.start_date,
      sortable: true,
      wrap:true,
    },
    {
      name: 'End Date',
      selector: row => row.end_date,
      sortable: true,
      wrap:true,
    },
    {
      name: 'Manage',
      ignoreRowClick: true,
      grow:1,
      width:"200px",
      cell: row =>(<Row> 
        <Col>       
         <Button title='Data' outline color='primary' className='manage-event' onClick={()=>viewEventAttendee(row.index,row.event_id,row)}>Data</Button>
        {location.state.isResult?
        
      (         <Button title='Result' color="primary" className='manage-event' onClick={()=>viewEventResult(row.index,row.event_id,row)}>Result</Button>
      ):
    (<div></div>)}


    </Col>

      </Row>
      
      ),
  
    },
  ],[],);
  
  




 
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <p className='page-heading'>{location.state.event.event_category}<nbsp/> 
      
      </p>

     
      <ToastContainer />

      <div className='page-body'>


      <Card
  className="my-2"
  style={{padding:0}}
 
>
      <CardBody className='hotel-locations-card-body'>
        {loadingGetFileEventList ? (
          <div className="loader" >
             <ContentLoader 
                speed={1}
                width={'100%'}
                height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                {Array.from({ length: 10 }, (_, index) => (
                  <rect
                    key={index}
                    x="0"
                    y={(70 + 4) * index}
                    rx="5"
                    ry="5"
                    width="100%"
                    height="70"
                  />
                ))}
              </ContentLoader>
          </div>
        ) : (
          <DataTable
            columns={columns}
            theme=""
            data={fileEventList}
            highlightOnHover
            fixedHeader
            pagination
            sortIcon={<FilterListIcon />}
          />
          )}
      </CardBody>
    </Card>


      </div>


    </div>
  );
}

export default EventList;
