import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_FAILURE,
  ACTIVATE_ACCOUNT_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  DEL_USER,
  DEL_USER_FAILURE,
  DEL_USER_SUCCESS
} from "./actionTypes";

const initialState = {
  userList: [],
  loading: false,
  error: {
    message: "",
    code:"",
  },
 
  activateAccountResponse: {},
  loadingActivateAccount: false,
  resetUserPasswordResponse: {},
  loadingResetUserPassword: false,
  loadingDelUser:false,
  delUserResponse:{},
};

const UsersReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_USERS:
      state = { ...state, loading: true };
      break;
    case GET_USERS_SUCCESS:
      state = { ...state, userList: action.payload.userList, loading: false };
      break;
    case GET_USERS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.message,
        },
        loading: false,
      };
      break;
     
    case ACTIVATE_ACCOUNT:
      state = { ...state, loadingActivateAccount: true };
      break;
    case ACTIVATE_ACCOUNT_SUCCESS:
      state = { ...state, activateAccountResponse: action.payload, loadingActivateAccount: false };

      break;
    case ACTIVATE_ACCOUNT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingActivateAccount: false,
      };
      break;

    case RESET_USER_PASSWORD:
      state = { ...state, loadingResetUserPassword: true };
      break;
    case RESET_USER_PASSWORD_SUCCESS:
      state = { ...state, resetUserPasswordResponse: action.payload, loadingResetUserPassword: false };

      break;
    case RESET_USER_PASSWORD_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingResetUserPassword: false,
      };
      break;
        
    case DEL_USER:
      state = { ...state, loadingDelUser: true };
      break;
    case DEL_USER_SUCCESS:
      state = { ...state, delUserResponse: action.payload, loadingDelUser: false };
      break;
    case DEL_USER_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelUser: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UsersReducer;
