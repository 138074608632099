import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS
} from "./actionTypes";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

export const tryLogin = (data,authLogin) => {
  return {
    type: LOGIN,
    payload: data,
    authLogin:authLogin,
  };
};

export const loginSuccess = (loginResponse) => {
  toast(loginResponse.message);
  return {
    type: LOGIN_SUCCESS,
    payload:loginResponse,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload:error,
  };
};


export const forgotPassword = (data,navigate) => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
    navigate:navigate,
  };
};

export const forgotPasswordSuccess = (forgotPasswordResponse) => {
  toast(forgotPasswordResponse.message);
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload:forgotPasswordResponse,
  };
};

export const forgotPasswordFailure = (error) => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload:error,
  };
};

export const resetPassword = (data,navigate) => {
  return {
    type: RESET_PASSWORD,
    payload: data,
    navigate:navigate,
  };
};

export const resetPasswordSuccess = (resetPasswordResponse) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload:resetPasswordResponse,
  };
};

export const resetPasswordFailure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload:error,
  };
};

export const changePassword = (data,navigate,token) => {
  return {
    type: CHANGE_PASSWORD,
    payload: data,
    navigate:navigate,
    token:token,
  };
};

export const changePasswordSuccess = (changePasswordResponse) => {
  toast(changePasswordResponse.message);
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload:changePasswordResponse,
  };
};

export const changePasswordFailure = (error) => {

  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload:error,
  };
};