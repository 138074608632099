import { useState } from 'react';
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { icon, title, items, menuToggle } = props;

  return (
    <div>
      <NavItem onClick={toggle} className={classNames({ "menu-open": !collapsed })}>
        <NavLink className="dropdown-toggle">
          {icon}
          {/* <FontAwesomeIcon icon={icon} className="mr-2" /> */}
          {title}
        </NavLink>
      </NavItem>
      <Collapse isOpen={!collapsed} navbar className={classNames("items-menu", { "mb-1": !collapsed })}>
        {items.map((item, index) => (
          <NavItem  onClick={menuToggle} key={index} className="pl-4">
            <NavLink   tag={Link} to={item.target}>
              {item.title}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
    </div>
  );
};

export default SubMenu;
