import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEvents,delEventFile ,addEvent} from '../../store/eventResultsDisplay/actions';
import "../../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../hooks/useAuth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {  Col,Alert, Card, CardHeader, CardBody, Table, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import ContentLoader from "react-content-loader";

createTheme('data-table', {
  text: {
    primary: '#000',
    secondary: '#2aa198',
  },
  background: {
    default: '#FFF',
  },
  context: {
    background: '#cb4b16',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

function EventResultsDisplay() {

  const { user,logout } = useAuth();
  const [modal, setModal] = useState(false);
  const [fileId, setId] = useState();

  const toggle = (fileId) => {
    setModal(!modal);
    setId(fileId);
  }
  const navigate = useNavigate();
  const { eventList, loading,delFileResponse,loadingDelFile,error } = useSelector((state) => state.EventResultsDisplayReducer);


  const {  loadingAddEvent } = useSelector((state) => state.EventResultsDisplayReducer);
  const [file, setFile] = useState();

  if(!loading&&error.message==="Unauthorised Access"){
    logout();
  }
  const columns = useMemo(()=>[
  
    {
      name: 'File Name',
      selector: row => row.event_name,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      id:'date',
      name: 'File Created Date',
      selector: row => row.created_at,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Manage',
      ignoreRowClick: true,
      grow:1,
      width:"200px",
      cell: row =>(<Row> 
        {row.status==="completed"?(
                  <Col>       

           <Button title='Delete' outline  className='manage-event' onClick={()=>toggle(row.event_file_id)}><DeleteForeverIcon/></Button>
           </Col>

        ):(
          <Col>       

<Button title='We are processing this file. Please wait!' color='primary'  className='manage-event' size='sm' outline><AssignmentLateIcon/> In Progress</Button>
          {/* <Button title='Delete' outline  className='manage-event' onClick={()=>toggle(row.event_file_id)}><DeleteForeverIcon/></Button> */}
          </Col>
        )
        
      }
        

      </Row>
      
      ),
  
    },
  ],[],);
  
  
  const deleteFile = (fileId) => {
    const delData = {
      "event_file_id": fileId
    };
    console.log(delData);
    dispatch(delEventFile(delData, navigate, user.token));
  }



  let dispatch = useDispatch();

  useEffect(() => {
    console.log("tokenn",user.token);
    if(!loadingAddEvent&&!loadingDelFile)
{
  setModal(false);
  document.getElementById("upload-result-file")?.reset();
    dispatch(getEvents(user.token));
}
    // let interval = setInterval(()=>dispatch(getEvents()),60000);
    // return () => {
    //   clearInterval(interval);
    // };

  }, [loadingAddEvent,loadingDelFile]);
  if(!loadingAddEvent&&error.message==="Unauthorised Access"){
    logout();
  }
  function handleChange(e) {
    console.log("file", e.target.files);
    const files = Array.from(e.target.files);
    getExtension(files[0]["name"]).toLowerCase() === "xlsx" ? setFile(files[0]) : toast("Unsupported File")
    // setFile(files[0]);
  }
  function getExtension(filename) {
    return filename.split('.').pop()
  }
  const addNewEvent = async (event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log("add new event clicked", data.get("eventName"));
    const formData = new FormData();

    formData.append('file', file);
    formData.append('event_name', data.get("eventName"));
    // formData.append('event_category', data.get("category"));

    console.log(formData);
    if (file != null)
      dispatch(addEvent(formData, navigate, user.token));
    else
      toast("Please upload xlsx file");
  };
  
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <p className='page-heading'>Event Results Display <nbsp/> 
      <span class="right-btn">
      <Button color='primary' outline type='submit' href="/results" target="_blank">View Results</Button>

</span>
      </p>
     
     
      <ToastContainer />

      <div className='page-body'>


      <Card
  className="my-2"
  style={{padding:0}}
 
>
  <CardHeader>


  <Form id='upload-result-file' onSubmit={addNewEvent}>



<FormGroup row>
 
  <Col sm={5}>
    <Input
      id="eventName"
      name="eventName"
      placeholder="Result Display File Name"
      type="text"
      required
    />
  </Col>
  <Col sm={5}>
    <Input
      id="eventFile"
      name="eventFile"
      type="file"
      onChange={handleChange}
      required
    />
    <FormText>
      Accepted File Type .xlsx Only.
    </FormText>
  </Col>
  <Col
    sm={2}
  >

    <Button color='primary' outline type='submit'>
      {loadingAddEvent ? (
        'Please wait...'
      ) : (
        'Upload New File'
      )}
    </Button>
    {loadingAddEvent ? (

      <Alert color="primary">
        We are uploading & processing your file. It can take few minutes...
      </Alert>

    ) :
      ('')}
    <ToastContainer />

  </Col>
</FormGroup>


</Form>

  </CardHeader>
<CardBody >

        {loading ? (
          <div className="loader" >
            <ContentLoader 
              speed={1}
              width={'100%'}
              height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
              viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {Array.from({ length: 10 }, (_, index) => (
                <rect
                  key={index}
                  x="0"
                  y={(70 + 4) * index}
                  rx="5"
                  ry="5"
                  width="100%"
                  height="70"
                />
              ))}
            </ContentLoader>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={eventList}
            theme=""
            pagination
            paginationRowsPerPageOptions={[50,100]}
            paginationPerPage={50}
            highlightOnHover
            fixedHeader
            defaultSortAsc={false}
            defaultSortFieldId={"date"}
            sortIcon={<FilterListIcon />}
          />
        )}
</CardBody>
      </Card>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Entry</ModalHeader>
        <ModalBody>
          Are you sure you want to Delete this Event File?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteFile(fileId)}>
            {loadingDelFile ? ('Deleting...') : ('Yes, Delete')}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

export default EventResultsDisplay;
