import { Button } from 'reactstrap';
import React from 'react';
import { Col, Alert, CardBody, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadGroupFile } from '../../store/dogs/actions';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';

function AddNewBreedGroupsFile() {

  const { loadingAddFile, error} = useSelector((state) => state.DogsReducer);
  const [file, setFile] = useState();
  const { user, logout } = useAuth();

  let dispatch = useDispatch();
  const navigate = useNavigate();

  if(!loadingAddFile && error.message === "Unauthorised Access"){
    logout();
  }

  function handleChange(e) {
    const files = Array.from(e.target.files);
    getExtension(files[0]["name"]).toLowerCase() === "xlsx" ? setFile(files[0]) : toast("Unsupported File")
  }

  function getExtension(filename) {
    return filename.split('.').pop()
  }

  const uploadNewFile = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = new FormData();

    formData.append('file', file);
    formData.append('file_name', data.get("fileName"));
    formData.append('file_year', data.get("fileYear"));

    if (file != null)
      dispatch(uploadGroupFile(formData, navigate, user.token));
    else
      toast("Please upload xlsx file");
  };

  const cancelUpload = () => {
    navigate('/dashboard/allBreedsTable');
  }

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Upload New Groups File</p>
      <div className='page-body' >
        <CardBody>
          <Form onSubmit={uploadNewFile}>
            <FormGroup row>
              <Label for="fileName" sm={2}> File Name</Label>
              <Col sm={10}>
                <Input id="fileName" name="fileName" placeholder="File name here" type="text" required />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="fileYear" sm={2}> File Year </Label>
              <Col sm={10}>
                <Input id="fileYear" name="fileYear" type="select" required>
                  {[...Array(2)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="file" sm={2}> Select File</Label>
              <Col sm={10}>
                <Input
                  id="groupfile"
                  name="groupfile"
                  type="file"
                  onChange={handleChange}
                  required
                />
                <FormText>
                  Accepted File Type .xlsx Only.
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ offset: 2, size: 10 }}>
                <Button color='primary' outline type='submit'>
                  {loadingAddFile ? (
                    'Please wait...'
                  ) : (
                    'Upload File'
                  )}
                </Button>{' '}
                {loadingAddFile ? (
                  <Alert color="primary">
                    We are uploading & processing your file. It can take few minutes...
                  </Alert>
                ) :
                ('')}
                <Button color="secondary" onClick={cancelUpload}>
                  Cancel
                </Button>
                <ToastContainer />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </div>
    </div>
  );
}

export default AddNewBreedGroupsFile;
