import { all, fork } from "redux-saga/effects";

import LoginSaga from "./adminLogin/saga";
import EventsSaga from "./events/saga";
import DogsSaga from "./dogs/saga";
import PostingDatesSaga from "./postingDates/saga";
import UsersSaga from "./users/saga";
import EventResultsDisplaySaga from "./eventResultsDisplay/saga";
import statsSaga from "./stats/saga";

export default function* rootSaga() {
  yield all([fork(LoginSaga)]);
  yield all([fork(EventsSaga)]);
  yield all([fork(DogsSaga)]);
  yield all([fork(PostingDatesSaga)]);
  yield all([fork(UsersSaga)]);
  yield all([fork(EventResultsDisplaySaga)]);
  yield all([fork(statsSaga)]);
}
