import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_EVENT_ATTENDEE,
  GET_EVENT_ATTENDEE_SUCCESS,
  GET_EVENT_ATTENDEE_FAIL,
  GET_EVENT_RESULT,
  GET_EVENT_RESULT_SUCCESS,
  GET_EVENT_RESULT_FAIL,
  CALCULATE_EVENT_RESULT,
  CALCULATE_EVENT_RESULT_SUCCESS,
  CALCULATE_EVENT_RESULT_FAILURE,
  GET_EVENT_LIST,
  GET_EVENT_LIST_FAIL,
  GET_EVENT_LIST_SUCCESS,
  GET_EVENT_PUBLIC_RESULT,
  GET_EVENT_PUBLIC_RESULT_SUCCESS,
  GET_EVENT_PUBLIC_RESULT_FAIL,
  DEL_EVENT_FILE,
  DEL_EVENT_FILE_SUCCESS,
  DEL_EVENT_FILE_FAILURE,
  CALCULATE_OVERALL_RESULT,
  CALCULATE_OVERALL_RESULT_SUCCESS,
  CALCULATE_OVERALL_RESULT_FAILURE,
  GET_OVERALL_RESULT,
  GET_OVERALL_RESULT_SUCCESS,
  GET_OVERALL_RESULT_FAIL,
  GET_CLUB_WISE_RANKING,
  GET_CLUB_WISE_RANKING_SUCCESS,
  GET_CLUB_WISE_RANKING_FAIL,
  GET_DOG_RESULT,
  GET_DOG_RESULT_FAIL,
  GET_DOG_RESULT_SUCCESS,
  DOWNLOAD_CERTIFICATE,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAIL,
  GET_FILE_RESULTS,
  GET_FILE_RESULTS_SUCCESS,
  GET_FILE_RESULTS_FAIL,
  DOWNLOAD_LETTER,
  DOWNLOAD_LETTER_SUCCESS,
  DOWNLOAD_LETTER_FAIL,
  SEND_CERTIFICATE,
  SEND_CERTIFICATE_SUCCESS,
  SEND_CERTIFICATE_FAIL,
  SEND_LETTER,
  SEND_LETTER_SUCCESS,
  SEND_LETTER_FAIL,
  GET_BATCH_RESULT,
  GET_BATCH_RESULT_SUCCESS,
  GET_BATCH_RESULT_FAIL,
  BATCH_PRINT,
  BATCH_PRINT_SUCCESS,
  BATCH_PRINT_FAIL,
  ADD_COR_EVENT,
  ADD_COR_EVENT_SUCCESS,
  ADD_COR_EVENT_FAILURE,
  GET_COR_RESULT,
  GET_COR_RESULT_SUCCESS,
  GET_COR_RESULT_FAIL,
  CUSTOM_CERTIFICATE,
  CUSTOM_CERTIFICATE_SUCCESS,
  CUSTOM_CERTIFICATE_FAIL,
  DIGITAL_CERTIFICATE,
  DIGITAL_CERTIFICATE_SUCCESS,
  DIGITAL_CERTIFICATE_FAIL
} from "./actionTypes";

export const getEvents= (data,token) => {
  return {
    type: GET_EVENTS,
    payload: data,
    token: token
  };
};

export const getEventsSuccess = (eventList) => {
  return {
    type: GET_EVENTS_SUCCESS,
    payload: eventList,
  };
};

export const getEventsFail = (error) => {
  return {
    type:GET_EVENTS_FAIL,
    payload: error,
  };
};

export const addEvent = (data,navigate,token) => {
  return {
    type: ADD_EVENT,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const addEventSuccess = (addEventResponse) => {
  return {
    type: ADD_EVENT_SUCCESS,
    payload:addEventResponse,
  };
};

export const addEventFailure = (error) => {
  return {
    type: ADD_EVENT_FAILURE,
    payload:error,
  };
};



export const getEventAttendee= (eventId,token) => {
  return {
    type: GET_EVENT_ATTENDEE,
    payload:eventId,
token:token
  };
};

export const getEventAttendeeSuccess = (attendeeList) => {
  return {
    type: GET_EVENT_ATTENDEE_SUCCESS,
    payload: attendeeList,
  };
};

export const getEventAttendeeFail = (error) => {
  return {
    type: GET_EVENT_ATTENDEE_FAIL,
    payload: error,
  };
};

// EVENT RESULT

export const getEventResult= (eventId,token) => {
  return {
    type: GET_EVENT_RESULT,
    payload:eventId,
token:token
  };
};

export const getEventResultSuccess = (rankingList) => {
  return {
    type: GET_EVENT_RESULT_SUCCESS,
    payload: rankingList,
  };
};

export const getEventResultFail = (error) => {
  return {
    type: GET_EVENT_RESULT_FAIL,
    payload: error,
  };
};



export const calculateEventResult = (data,navigate,token) => {
  return {
    type: CALCULATE_EVENT_RESULT,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const calculateEventResultSuccess = (calculateEventResultResponse) => {
  return {
    type: CALCULATE_EVENT_RESULT_SUCCESS,
    payload:calculateEventResultResponse,
  };
};

export const calculateEventResultFailure = (error) => {
  return {
    type: CALCULATE_EVENT_RESULT_FAILURE,
    payload:error,
  };
};



/// GET EVENT LIST FROM EVENT FILE CATEGORY


// EVENT RESULT

export const getEventList= (eventFileId,token) => {
  return {
    type: GET_EVENT_LIST,
    payload:eventFileId,
token:token
  };
};

export const getEventListSuccess = (eventList) => {
  return {
    type: GET_EVENT_LIST_SUCCESS,
    payload: eventList,
  };
};

export const getEventListFail = (error) => {
  return {
    type: GET_EVENT_LIST_FAIL,
    payload: error,
  };
};



// EVENT PUBLIC RESULT

export const getEventPublicResult= (eventId) => {
  return {
    type: GET_EVENT_PUBLIC_RESULT,
    payload:eventId,
  };
};

export const getEventPublicResultSuccess = (rankingList) => {
  return {
    type: GET_EVENT_PUBLIC_RESULT_SUCCESS,
    payload: rankingList,
  };
};

export const getEventPublicResultFail = (error) => {
  return {
    type: GET_EVENT_PUBLIC_RESULT_FAIL,
    payload: error,
  };
};


// delete event file


export const delEventFile = (data,navigate,token) => {
  return {
    type: DEL_EVENT_FILE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delEventFileSuccess = (response) => {
  return {
    type: DEL_EVENT_FILE_SUCCESS,
    payload:response,
  };
};

export const delEventFileFailure = (error) => {
  return {
    type: DEL_EVENT_FILE_FAILURE,
    payload:error,
  };
};

// CALCULATE OVERALL RESULT


export const calculateOverallResult = (data,navigate,token) => {
  return {
    type: CALCULATE_OVERALL_RESULT,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const calculateOverallResultSuccess = (response) => {
  return {
    type: CALCULATE_OVERALL_RESULT_SUCCESS,
    payload:response,
  };
};

export const calculateOverallResultFailure = (error) => {
  return {
    type: CALCULATE_OVERALL_RESULT_FAILURE,
    payload:error,
  };
};

// GET OVERALL RESULT


export const getOverallResult= (eventId,token) => {
  return {
    type: GET_OVERALL_RESULT,
    payload:eventId,
    token:token
  };
};

export const getOverallResultSuccess = (rankingList) => {
  return {
    type: GET_OVERALL_RESULT_SUCCESS,
    payload: rankingList,
  };
};

export const getOverallResultFail = (error) => {
  return {
    type: GET_OVERALL_RESULT_FAIL,
    payload: error,
  };
};

// GET CLUB WISE RANKING


export const getClubWiseRanking= (eventId) => {
  return {
    type: GET_CLUB_WISE_RANKING,
    payload:eventId,
  };
};

export const getClubWiseRankingSuccess = (rankingList) => {
  return {
    type: GET_CLUB_WISE_RANKING_SUCCESS,
    payload: rankingList,
  };
};

export const getClubWiseRankingFail = (error) => {
  return {
    type: GET_CLUB_WISE_RANKING_FAIL,
    payload: error,
  };
};


// GET DOG RESULT FROM ATTENDEE TABLE

export const getDogResult= (dog_number,token) => {
  return {
    type: GET_DOG_RESULT,
    payload:dog_number,
token:token
  };
};

export const getDogResultSuccess = (attendeeList) => {
  return {
    type: GET_DOG_RESULT_SUCCESS,
    payload: attendeeList,
  };
};

export const getDogResultFail = (error) => {
  return {
    type: GET_DOG_RESULT_FAIL,
    payload: error,
  };
};

export const downloadStandardCertificate = (data, token) => {
  return {
    type: DOWNLOAD_CERTIFICATE,
    payload: data,
    token:token
  };
};

export const getDownloadCertificateSuccess = (data) => {
  return {
    type: DOWNLOAD_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

export const getDownloadCertificateFail = (error) => {
  return {
    type: DOWNLOAD_CERTIFICATE_FAIL,
    payload: error,
  };
};

export const getFileResults= (data,token) => {
  return {
    type: GET_FILE_RESULTS,
    payload:data,
    token:token
  };
};

export const getFileResultsSuccess = (res) => {
  return {
    type: GET_FILE_RESULTS_SUCCESS,
    payload: res,
  };
};

export const getFileResultsFail = (error) => {
  return {
    type: GET_FILE_RESULTS_FAIL,
    payload: error,
  };
};

export const downloadStandardLetter = (data, token) => {
  return {
    type: DOWNLOAD_LETTER,
    payload: data,
    token:token
  };
};

export const getDownloadLetterSuccess = (data) => {
  return {
    type: DOWNLOAD_LETTER_SUCCESS,
    payload: data,
  };
};

export const getDownloadLetterFail = (error) => {
  return {
    type: DOWNLOAD_LETTER_FAIL,
    payload: error,
  };
};

export const sendStandardCertificate = (data,token) => {
  return {
    type: SEND_CERTIFICATE,
    payload: data,
    token:token
  };
};

export const sendStandardCertificateSuccess = (data) => {
  return {
    type: SEND_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

export const sendStandardCertificateFail = (error) => {
  return {
    type: SEND_CERTIFICATE_FAIL,
    payload: error,
  };
};

export const sendStandardLetter = (data,token) => {
  return {
    type: SEND_LETTER,
    payload: data,
    token:token
  };
};

export const sendStandardLetterSuccess = (data) => {
  return {
    type: SEND_LETTER_SUCCESS,
    payload: data,
  };
};

export const sendStandardLetterFail = (error) => {
  return {
    type: SEND_LETTER_FAIL,
    payload: error,
  };
};

//Batch Result
export const getBatchResult= (data,token) => {
  return {
    type: GET_BATCH_RESULT,
    payload:data,
    token:token
  };
};

export const getBatchResultSuccess = (rankingList) => {
  return {
    type: GET_BATCH_RESULT_SUCCESS,
    payload: rankingList,
  };
};

export const getBatchResultFail = (error) => {
  return {
    type: GET_BATCH_RESULT_FAIL,
    payload: error,
  };
};

export const batchPrint= (data,token) => {
  return {
    type: BATCH_PRINT,
    payload:data,
    token:token
  };
};

export const batchPrintSuccess = (data) => {
  return {
    type: BATCH_PRINT_SUCCESS,
    payload: data,
  };
};

export const batchPrintFail = (error) => {
  return {
    type: BATCH_PRINT_FAIL,
    payload: error,
  };
};

export const addCOREvent = (data,navigate,token) => {
  return {
    type: ADD_COR_EVENT,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const addCOREventSuccess = (addEventResponse) => {
  return {
    type: ADD_COR_EVENT_SUCCESS,
    payload:addEventResponse,
  };
};

export const addCOREventFailure = (error) => {
  return {
    type: ADD_COR_EVENT_FAILURE,
    payload:error,
  };
};

// GET COR RESULTS
export const getCorResult= (eventId,token) => {
  return {
    type: GET_COR_RESULT,
    payload:eventId,
    token:token
  };
};

export const getCorResultSuccess = (rankingList) => {
  return {
    type: GET_COR_RESULT_SUCCESS,
    payload: rankingList,
  };
};

export const getCorResultFail = (error) => {
  return {
    type: GET_COR_RESULT_FAIL,
    payload: error,
  };
};

//Create Custom Certificate
export const createCustomCertificate = (data, token) => {
  return {
    type: CUSTOM_CERTIFICATE,
    payload: data,
    token:token
  };
};

export const createCustomCertificateSuccess = (data) => {
  return {
    type: CUSTOM_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

export const createCustomCertificateFail = (error) => {
  return {
    type: CUSTOM_CERTIFICATE_FAIL,
    payload: error,
  };
};

export const downloadDigitalCertificate = (data) => {
  return {
    type: DIGITAL_CERTIFICATE,
    payload: data
  };
};

export const getDigitalCertificateSuccess = (data) => {
  return {
    type: DIGITAL_CERTIFICATE_SUCCESS,
    payload: data,
  };
};

export const getDigitalCertificateFail = (error) => {
  return {
    type: DIGITAL_CERTIFICATE_FAIL,
    payload: error,
  };
};