import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_EVENTS,
  ADD_EVENT,
  GET_EVENT_ATTENDEE,
  GET_EVENT_RESULT,
  CALCULATE_EVENT_RESULT,
  GET_EVENT_LIST,
  GET_EVENT_PUBLIC_RESULT,
  DEL_EVENT_FILE,
  CALCULATE_OVERALL_RESULT,
  GET_OVERALL_RESULT,
  GET_CLUB_WISE_RANKING,
  GET_DOG_RESULT,
  DOWNLOAD_CERTIFICATE,
  GET_FILE_RESULTS,
  DOWNLOAD_LETTER,
  SEND_CERTIFICATE,
  SEND_LETTER,
  GET_BATCH_RESULT,
  BATCH_PRINT,
  ADD_COR_EVENT,
  GET_COR_RESULT,
  CUSTOM_CERTIFICATE,
  DIGITAL_CERTIFICATE
} from "./actionTypes";

import {
  getEventsSuccess,
  getEventsFail,
  addEventSuccess,
  addEventFailure,
  getEventAttendeeFail,
  getEventAttendeeSuccess,
  getEventResultSuccess,
  getEventResultFail,
  calculateEventResultSuccess,
  calculateEventResultFailure,
  getEventListFail,
  getEventListSuccess,
  getEventPublicResultSuccess,
  getEventPublicResultFail,
  delEventFileSuccess,
  delEventFileFailure,
  calculateOverallResultSuccess,
  calculateOverallResultFailure,
  getOverallResultSuccess,
  getOverallResultFail,
  getClubWiseRankingSuccess,
  getClubWiseRankingFail,
  getDogResultFail,
  getDogResultSuccess,
  getDownloadCertificateSuccess,
  getDownloadCertificateFail,
  getFileResultsFail,
  getFileResultsSuccess,
  getDownloadLetterSuccess,
  getDownloadLetterFail,
  sendStandardCertificateSuccess,
  sendStandardCertificateFail,
  sendStandardLetterSuccess,
  sendStandardLetterFail,
  getBatchResultSuccess,
  getBatchResultFail,
  batchPrintSuccess,
  batchPrintFail,
  addCOREventSuccess,
  addCOREventFailure,
  getCorResultSuccess,
  getCorResultFail,
  createCustomCertificateSuccess,
  createCustomCertificateFail,
  getDigitalCertificateSuccess,
  getDigitalCertificateFail
} from "./actions";

import {
  getEvents,
  addEvent,
  getEventAttendees,
  getEventResult,
  calculateEventResult,
  getEventPublicResult,
  getEventList,
  delEventFile,
  calcualteOverallResult,
  getOverallResult,
  getClubWiseRanking,
  getDogResult,
  downloadStandardCertificate,
  getFileResults,
  downloadStandardLetter,
  sendStandardCertificate,
  sendStandardLetter,
  getBatchResult,
  batchPrint,
  addCOREvent,
  getCorResult,
  createCustomCertificate,
  downloadDigitalCertificate
} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function* onAddEvent({ payload: data, token, navigate }) {


  try {
    const response = yield call(addEvent, data, token);
    //console.log(response);
    if (response.statusCode === 200) {
      navigate("/dashboard/allEventFiles");
    }
    yield put(addEventSuccess(response.data));
  } catch (error) {
    yield put(addEventFailure(error.response));
  }
}

function* onGetEvents({ payload: data, token }) {


  try {
    const response = yield call(getEvents, data, token);
    //console.log(response);

    yield put(getEventsSuccess(response.data));
  } catch (error) {

    yield put(getEventsFail(error.response));
  }
}


function* onGetEventAttendees({ payload: eventId, token }) {


  try {
    const response = yield call(getEventAttendees, eventId, token);
    //console.log(response);

    yield put(getEventAttendeeSuccess(response.data));
  } catch (error) {
    yield put(getEventAttendeeFail(error.response));
  }
}


function* onGetDogResult({ payload: dog_number, token }) {


  try {
    const response = yield call(getDogResult, dog_number, token);
    //console.log(response);

    yield put(getDogResultSuccess(response.data));
  } catch (error) {
    yield put(getDogResultFail(error.response));
  }
}

function* onGetEventResult({ payload: eventId, token }) {


  try {
    const response = yield call(getEventResult, eventId, token);
    //console.log(response);

    yield put(getEventResultSuccess(response.data));
  } catch (error) {
    yield put(getEventResultFail(error.response));
  }
}


function* onGetOverallResult({ payload: eventId, token }) {


  try {
    const response = yield call(getOverallResult, eventId, token);
    //console.log(response);

    yield put(getOverallResultSuccess(response.data));
  } catch (error) {
    yield put(getOverallResultFail(error.response));
  }
}



function* onCalculateEventResult({ payload: data, token, navigate }) {


  try {
    const response = yield call(calculateEventResult, data, token);
    //console.log(response);
    // toast(response.message);
    if (response.statusCode === 200) {
      navigate(0);

    }
    yield put(calculateEventResultSuccess(response.data));
  } catch (error) {
    yield put(calculateEventResultFailure(error.response));
  }
}

function* onCalculateOverallResult({ payload: data, token, navigate }) {


  try {
    const response = yield call(calcualteOverallResult, data, token);
    //console.log(response);
    if (response.statusCode === 200) {
     // navigate(0);
    }
    yield put(calculateOverallResultSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(calculateOverallResultFailure(error.response));
  }
}



function* onGetEventList({ payload: eventFileId, token }) {


  try {
    const response = yield call(getEventList, eventFileId, token);
    //console.log("Event List", response);

    yield put(getEventListSuccess(response.data));
  } catch (error) {
    yield put(getEventListFail(error.response));
  }
}




function* onGetEventPublicResult({ payload: eventId }) {


  try {
    const response = yield call(getEventPublicResult, eventId);
    //console.log(response);

    yield put(getEventPublicResultSuccess(response.data));
  } catch (error) {
    yield put(getEventPublicResultFail(error.response));
  }
}



function* onDelFile({ payload: data, navigate, token }) {


  try {
    const response = yield call(delEventFile, data, token);
    //console.log(response);
    toast(response.message);
    if (response.statusCode === 200) {
      navigate(0);
    }
    yield put(delEventFileSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delEventFileFailure(error.response));
  }
}


function* onGetClubWiseRanking({ payload: eventId }) {

  try {
    const response = yield call(getClubWiseRanking, eventId);
    //console.log(response);

    yield put(getClubWiseRankingSuccess(response.data));
  } catch (error) {
    yield put(getClubWiseRankingFail(error.response));
  }
}

function* onDownloadCertificate({ payload: data, token }) {

  try {
    const response = yield call(downloadStandardCertificate, data, token);
    //console.log(response);

    yield put(getDownloadCertificateSuccess(response.data));
  } catch (error) {
    yield put(getDownloadCertificateFail(error.response));
  }
}

function* onGetFileResults({ payload: data, token }) {


  try {
    const response = yield call(getFileResults, data, token);
    console.log(response);

    yield put(getFileResultsSuccess(response.data));
  } catch (error) {
    yield put(getFileResultsFail(error.response));
  }
}

function* onDownloadLetter({ payload: data, token }) {

  try {
    const response = yield call(downloadStandardLetter, data, token);
    //console.log(response);

    yield put(getDownloadLetterSuccess(response.data));
  } catch (error) {
    yield put(getDownloadLetterFail(error.response));
  }
}

function* onSendCertificate({ payload: data, token }) {

  try {
    const response = yield call(sendStandardCertificate, data, token);
    //toast(response.data);
    yield put(sendStandardCertificateSuccess(response.data));
  } catch (error) {
    yield put(sendStandardCertificateFail(error.response));
  }
}

function* onSendLetter({ payload: data, token }) {

  try {
    const response = yield call(sendStandardLetter, data, token);
    //toast(response.data);
    yield put(sendStandardLetterSuccess(response.data));
  } catch (error) {
    yield put(sendStandardLetterFail(error.response));
  }
}

function* onGetBatchResult({ payload: data, token }) {

  try {
    const response = yield call(getBatchResult, data, token);
    //toast(response.data);
    yield put(getBatchResultSuccess(response.data));
  } catch (error) {
    yield put(getBatchResultFail(error.response));
  }
}

function* onBatchPrint({ payload: data, token }) {

  try {
    const response = yield call(batchPrint, data, token);
    //console.log(response);

    yield put(batchPrintSuccess(response.data));
  } catch (error) {
    yield put(batchPrintFail(error.response));
  }
}

function* onAddCOREvent({ payload: data, token, navigate }) {

  try {
    const response = yield call(addCOREvent, data, token);
    //console.log(response);
    if (response.statusCode === 200) {
      navigate("/dashboard/allCORFiles");
    }
    yield put(addCOREventSuccess(response.data));
  } catch (error) {
    yield put(addCOREventFailure(error.response));
  }
}

function* onGetCorResult({ payload: eventId, token }) {
  try {
    const response = yield call(getCorResult, eventId, token);
    //console.log(response);

    yield put(getCorResultSuccess(response.data));
  } catch (error) {
    yield put(getCorResultFail(error.response));
  }
}

function* onGenerateCertificate({ payload: data, token }) {

  try {
    const response = yield call(createCustomCertificate, data, token);
    
    yield put(createCustomCertificateSuccess(response.data));
  } catch (error) {
    yield put(createCustomCertificateFail(error.response));
  }
}

function* onGenerateDigitalCertificate({ payload: data }) {

  try {
    const response = yield call(downloadDigitalCertificate, data);
    
    yield put(getDigitalCertificateSuccess(response.data));
  } catch (error) {
    yield put(getDigitalCertificateFail(error.response));
  }
}

function* EventsSaga() {
  yield takeLatest(GET_FILE_RESULTS, onGetFileResults);

  yield takeLatest(GET_EVENTS, onGetEvents);
  yield takeLatest(ADD_EVENT, onAddEvent);
  yield takeLatest(GET_EVENT_ATTENDEE, onGetEventAttendees);
  yield takeLatest(GET_EVENT_RESULT, onGetEventResult);
  yield takeLatest(CALCULATE_EVENT_RESULT, onCalculateEventResult);
  yield takeLatest(GET_EVENT_LIST, onGetEventList);
  yield takeLatest(GET_EVENT_PUBLIC_RESULT, onGetEventPublicResult);
  yield takeLatest(DEL_EVENT_FILE, onDelFile);
  yield takeLatest(CALCULATE_OVERALL_RESULT,onCalculateOverallResult);
  yield takeLatest(GET_OVERALL_RESULT,onGetOverallResult);
  yield takeLatest(GET_CLUB_WISE_RANKING,onGetClubWiseRanking);
  yield takeLatest(GET_DOG_RESULT,onGetDogResult);
  yield takeLatest(DOWNLOAD_CERTIFICATE,onDownloadCertificate);
  yield takeLatest(DOWNLOAD_LETTER,onDownloadLetter);
  yield takeLatest(SEND_CERTIFICATE,onSendCertificate);
  yield takeLatest(SEND_LETTER,onSendLetter);
  yield takeLatest(GET_BATCH_RESULT,onGetBatchResult);
  yield takeLatest(BATCH_PRINT,onBatchPrint);
  yield takeLatest(ADD_COR_EVENT, onAddCOREvent);
  yield takeLatest(GET_COR_RESULT,onGetCorResult);
  yield takeLatest(CUSTOM_CERTIFICATE,onGenerateCertificate);
  yield takeLatest(DIGITAL_CERTIFICATE,onGenerateDigitalCertificate);
}

export default EventsSaga;
