import {
  GET_POSTING_DATES,
  GET_POSTING_DATES_SUCCESS,
  GET_POSTING_DATES_FAIL,
  SAVE_POSTING_DATES,
  SAVE_POSTING_DATES_FAILURE,
  SAVE_POSTING_DATES_SUCCESS,
  EDIT_DISPLAY_YEAR,
  EDIT_DISPLAY_YEAR_SUCCESS,
  EDIT_DISPLAY_YEAR_FAIL,
} from "./actionTypes";

export const savePostingDates= (data,token) => {
  return {
    type: SAVE_POSTING_DATES,
    token:token,
    payload:data,
  };
};

export const savePostingDatesSuccess = (response) => {
  return {
    type:SAVE_POSTING_DATES_SUCCESS,
    payload: response,
  };
};

export const savePostingDatesFail = (error) => {
  return {
    type:SAVE_POSTING_DATES_FAILURE,
    payload: error,
  };
};


export const getPostingDates= (token) => {
  return {
    type: GET_POSTING_DATES,
    token:token
  };
};

export const getPostingDatesSuccess = (settings) => {
  return {
    type: GET_POSTING_DATES_SUCCESS,
    payload: settings,
  };
};

export const getPostingDatesFail = (error) => {
  return {
    type:GET_POSTING_DATES_FAIL,
    payload: error,
  };
};

export const editDisplayYear= (data,navigate,token) => {
  return {
    type: EDIT_DISPLAY_YEAR,
    payload: data,
    navigate:navigate,
    token: token
  };
};

export const editDisplayYearSuccess = (settings) => {
  return {
    type: EDIT_DISPLAY_YEAR_SUCCESS,
    payload: settings,
  };
};

export const editDisplayYearFail = (error) => {
  return {
    type: EDIT_DISPLAY_YEAR_FAIL,
    payload: error,
  };
};

