import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS
} from "./actionTypes";

const initialState = {
 
  loginResponse: {},
  forgotPasswordResponse: {},
  resetPasswordResponse:{},
  changePasswordResponse:{},
  loadingChangePassword:false,
  loadingResetPassword:false,

  loading: false,
  loadingForgotPassword: false,

  error: {
    message: "",
  },
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {


    case LOGIN:
      state = { ...state, loading: true };
      break;
    case LOGIN_SUCCESS:
      state = { ...state, loginResponse: action.payload, loading: false };

      break;
    case LOGIN_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
      case FORGOT_PASSWORD:
        state = { ...state, loadingForgotPassword: true };
        break;
      case FORGOT_PASSWORD_SUCCESS:
        state = { ...state, forgotPasswordResponse: action.payload, loadingForgotPassword: false };
  
        break;
      case FORGOT_PASSWORD_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingForgotPassword: false,
        };
        break;

      case RESET_PASSWORD:
        state = { ...state, loadingResetPassword: true };
        break;
      case RESET_PASSWORD_SUCCESS:
        state = { ...state, resetPasswordResponse: action.payload, loadingResetPassword: false };
  
        break;
      case RESET_PASSWORD_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingResetPassword: false,
        };
        break;



      case CHANGE_PASSWORD:
        state = { ...state, loadingChangePassword: true };
        break;
      case CHANGE_PASSWORD_SUCCESS:
        state = { ...state, changePasswordResponse: action.payload, loadingChangePassword: false };
  
        break;
      case CHANGE_PASSWORD_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingChangePassword: false,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginReducer;
