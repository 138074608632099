import {
  GET_POSTING_DATES,
  GET_POSTING_DATES_SUCCESS,
  GET_POSTING_DATES_FAIL,
  SAVE_POSTING_DATES,
  SAVE_POSTING_DATES_FAILURE,
  SAVE_POSTING_DATES_SUCCESS,
  EDIT_DISPLAY_YEAR,
  EDIT_DISPLAY_YEAR_SUCCESS,
  EDIT_DISPLAY_YEAR_FAIL
} from "./actionTypes";

const initialState = {
 
  error: {
    message: "",
    code:"",
  },
  savePostingDatesResponse: {},
  loading: false,

  postingDatesList: [],
  loadingSavePostingDates: false,

};

const PostingDatesReducer = (state = initialState, action) => {


  switch (action.type) {
    case SAVE_POSTING_DATES:
      state = { ...state, loadingSavePostingDates: true };
      break;
    case SAVE_POSTING_DATES_SUCCESS:
      state = { ...state, savePostingDatesResponse: action.payload, loadingSavePostingDates: false };
      break;
    case SAVE_POSTING_DATES_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSavePostingDates: false,
      };
      break;
    case GET_POSTING_DATES:
      state = { ...state, loading: true };
      break;
    case GET_POSTING_DATES_SUCCESS:
      state = { ...state, postingDatesList: action.payload.postingDateList, loading: false };
      break;
    case GET_POSTING_DATES_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.message
                },
        loading: false,
      };
      break;
    case EDIT_DISPLAY_YEAR:
      state = { ...state, loading: true };
      break;
    case EDIT_DISPLAY_YEAR_SUCCESS:
      state = { ...state, postingDatesResponse: action.payload, loading: false };
      break;
    case EDIT_DISPLAY_YEAR_FAIL:
      state = {
        ...state,
        error: { message: action.payload.message },
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PostingDatesReducer;
