import './App.css';
import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import {  Welcome } from "./pages/Welcome";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import store from "./store/index";
import { Provider } from "react-redux";
import Dashboard from './pages/Dashboard';

import MyAccount from './pages/MyAccount';

import { ResetPassword } from './pages/ResetPassword';
import { ForgotPassword } from './pages/ForgotPassword';
import { ChangePassword } from './pages/ChangePassword';
import EventAttendees from './pages/EventAttendees';
import "bootstrap/dist/css/bootstrap.min.css";
import Dogs from './pages/Dogs';
import EventResult from './pages/EventResult';
import EditDogData from './pages/EditDogData';
import EventCatList from './pages/EventCatList';
import AddNewEventsFile from './pages/AddNewEventsFile';
import AllEventFiles from './pages/AllEventFiles';
import EventList from './pages/EventList';
import { Listing } from './pages/Listing';
import OverallResults from './pages/OverallResults';
import { OverallRanking } from './pages/OverallRanking';
import { ClubWiseRanking } from './pages/ClubWiseRanking';
import { DogResult } from './pages/DogResult';
import PostingDates from './pages/PostingDates';
import { PublicRanking } from './pages/PublicRanking';
import PublicUrls from './pages/PublicUrls';
import Certificates from './pages/Certificates';
import EventFileResults from './pages/EventResultsDisplay/ViewResult/EventFileResults';
import EventResultsDisplay from './pages/EventResultsDisplay/EventResultsDisplay';
import AllUsers from './pages/AllUsers';
import AddNewUser from './pages/AddNewUser';
import BatchResultDisplay from './pages/BatchResultDisplay';
import CorResults from './pages/CorResults';
import AddNewCORFile from './pages/AddNewCORFile';
import AllCORFiles from './pages/AllCORFiles';
import ErrorPage from './pages/ErrorPage';
import CreateCertificate from './pages/CreateCertificate';
import AllBreedGroups from './pages/AllBreedGroups';
import AddNewBreedGroupsFile from './pages/AddNewBreedGroupsFile';
import AddNewBreedGroup from './pages/AddNewBreedGroup';
import EditBreedGroup from './pages/EditBreedGroup';
import PublicCertificates from './pages/PublicCertificates';
import YearLevel from './pages/stats/YearLevel';
import ShowLevel from './pages/stats/ShowLevel';
import Judges from './pages/stats/Judges';
import GroupLevel from './pages/stats/GroupLevel';

function App() {
  return (

    <Provider store={store}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/set-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="allEventFiles" element={<AllEventFiles />} />
          <Route path="event-attendees" element={<EventAttendees />} />
          <Route path="event-result" element={<EventResult />} />
          <Route path="event-categories" element={<EventCatList />} />
          <Route path="event-list" element={<EventList />} />
          <Route path="overall-results" element={<OverallResults />} />
          <Route path="posting-dates" element={<PostingDates />} />
          <Route path="public-urls" element={<PublicUrls />} />

          <Route path="dogs" element={<Dogs />} />
          <Route path="edit-dog" element={<EditDogData />} />

          <Route path="add-new-file" element={<AddNewEventsFile />} />
          <Route path="cor-results" element={<CorResults />} />
          <Route path="allCORFiles" element={<AllCORFiles />} />

          <Route path="year-level" element={<YearLevel />} />
          <Route path="group-level" element={<GroupLevel />} />
          <Route path="show-level" element={<ShowLevel />} />
          <Route path="judges" element={<Judges />} />
          
          <Route path="add-cor-file" element={<AddNewCORFile />} />
          <Route path="certificates" element={<Certificates />} />
          <Route path="create-certificate" element={<CreateCertificate />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="allUsers" element={<AllUsers />} />
          <Route path="add-new-user" element={<AddNewUser />} />
          <Route path='event-results-display' element={<EventResultsDisplay/>}/>
          <Route path="allBreedsTable" element={<AllBreedGroups />} />
          <Route path="add-breed-group-file" element={<AddNewBreedGroupsFile />} />
          <Route path="add-breed-group" element={<AddNewBreedGroup />} />
          <Route path="edit-breed-group" element={<EditBreedGroup />} />
          <Route path="digitalCertificates" element={<PublicCertificates />} />
        </Route>

        <Route path='/batch-result' element={<BatchResultDisplay/>}/>
        <Route path="/listing" element={<Listing />} />
        <Route path="/overall_ranking" element={<OverallRanking />} />
        <Route path="/ranking" element={<PublicRanking />} />

        <Route path="/club_wise_ranking" element={<ClubWiseRanking />} />
        <Route path="/dog-result" element={<DogResult />} />
        <Route path="/results" element={<EventFileResults />} />
        <Route path="/error-page" element={<ErrorPage />} />

      </Routes>
    </Provider>
  );
}

export default App;
