import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_EVENT_DISPLAY_RESULT_FILES,
  ADD_DISPLAY_EVENT_FILE,
  DEL_DISPLAY_EVENT_FILE,
} from "./actionTypes";

import {
  getEventsSuccess,
  getEventsFail,
  addEventSuccess,
  addEventFailure,
  delEventFileSuccess,
  delEventFileFailure,

} from "./actions";

import {
  getEventResultsDisplayFiles,
  addEventResultDisplay,

  delEventResultsDisplayFile,

} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function* onAddEvent({ payload: data, token, navigate }) {


  try {
    const response = yield call(addEventResultDisplay, data, token);
    console.log(response);
  
    yield put(addEventSuccess(response.data));
  } catch (error) {
    yield put(addEventFailure(error.response));
  }
}

function* onGetEvents({ payload: token }) {


  try {
    const response = yield call(getEventResultsDisplayFiles, token);
    console.log(response);

    yield put(getEventsSuccess(response.data));
  } catch (error) {

    yield put(getEventsFail(error.response));
  }
}


function* onDelFile({ payload: data, navigate, token }) {


  try {
    const response = yield call(delEventResultsDisplayFile, data, token);
    console.log(response);
    //toast(response.message);
   
    yield put(delEventFileSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delEventFileFailure(error.response));
  }
}









function* EventResultsDisplaySaga() {

  yield takeLatest(GET_EVENT_DISPLAY_RESULT_FILES, onGetEvents);
  yield takeLatest(ADD_DISPLAY_EVENT_FILE, onAddEvent);
   yield takeLatest(DEL_DISPLAY_EVENT_FILE, onDelFile);
  


}

export default EventResultsDisplaySaga;
