import { Root } from '@amcharts/amcharts5';
import React, { useEffect, useRef, useState } from 'react';
import { PieChart, PieSeries } from '@amcharts/amcharts5/percent';
import useTheme from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import { Label } from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowLevelStats } from '../../store/stats/actions';
import { useAuth } from '../../hooks/useAuth';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import ContentLoader from 'react-content-loader';

const ShowLevelProvince = ({ provinceName, dogShows, loading,noResults }) => {
    const { user, logout } = useAuth();
    const chartRef = useRef(null);
    const [chartHeight, setChartHeight] = useState(500);

    const formatteddogShows = dogShows && Array.isArray(dogShows)
        ? dogShows.map(item => ({
            ...item,
            NumOfEvents: Number(item.NumOfEvents),
            SingleBreed: Number(item.SingleBreed),
            MultiBreed: Number(item.MultiBreed),
            GroupBreed: Number(item.GroupBreed),
            LimitedBreed: Number(item.LimitedBreed),
            uniqueShowName: `${item.showName}-${item.dogShowId}`,
        })) : [];

    useEffect(() => {
        if (chartRef.current && formatteddogShows.length > 0) {
            const root = am5.Root.new(chartRef.current);

            // Apply themes
            root.setThemes([am5themes_Animated.new(root)]);

            // Create chart
            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    paddingLeft: 0,
                    layout: root.verticalLayout,
                })
            );

            // Add scrollbar
            chart.set(
                'scrollbarY',
                am5.Scrollbar.new(root, { orientation: 'vertical' })
            );

            // Create axes
            const yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: 'uniqueShowName',
                    renderer: am5xy.AxisRendererY.new(root, {
                        minGridDistance: 20,
                        labels: {
                            minWidth: 100,
                            fontSize: '12px'
                        }
                    }),
                })
            );

            // Use label adapter to show `showName` instead of `uniqueShowName`
            yAxis.get('renderer').labels.template.adapters.add('text', (text, target) => {
                const dataItem = target.dataItem;
                if (dataItem) {
                    const dataContext = dataItem.dataContext;
                    return dataContext ? dataContext.showName : text;
                }
                return text;
            });

            yAxis.data.setAll(formatteddogShows);

            const xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 1000,
                        labels: {
                            visible: false,
                        }
                    }),
                })
            );

            // Add legend
            const legend = chart.children.push(
                am5.Legend.new(root, { centerX: am5.p50, x: am5.p50 })
            );

            const fields = [
                'NumOfEvents',
                'SingleBreed',
                'MultiBreed',
                'GroupBreed',
                'LimitedBreed',
            ];

            const fieldLabels = {
                NumOfEvents: 'Number Of Events',
                SingleBreed: 'Single Breed',
                MultiBreed: 'Multi Breed',
                GroupBreed: 'Group Breed',
                LimitedBreed: 'Limited Breed',
            };

            const colorPalette = [
                am5.color(0xDAA520), // Golden Rod
                am5.color(0x6A5ACD), // Slate Blue
                am5.color(0x33FF57), // Green
                am5.color(0xFF5733), // Red
                am5.color(0x3357FF), // Blue
                am5.color(0xFF33FF), // Pink
                am5.color(0xFF4500), // Orange-Red
                am5.color(0x40E0D0), // Turquoise
                am5.color(0xDA70D6), // Orchid
                am5.color(0xFFFF33), // Yellow
                am5.color(0xFF33A1), // Magenta
                am5.color(0xDC143C), // Crimson
                am5.color(0x2E8B57), // Sea Green
                am5.color(0x8A2BE2), // Blue Violet
                am5.color(0xFF6347), // Tomato
                am5.color(0x4682B4), // Steel Blue
                am5.color(0xD2691E), // Chocolate
                am5.color(0xFF1493), // Deep Pink
                am5.color(0x00FA9A), // Medium Spring Green
                am5.color(0xFF8C00), // Dark Orange
                am5.color(0x7FFF00), // Chartreuse
            ];

            fields.forEach((attribute, index) => {
                const series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: fieldLabels[attribute],
                        stacked: true,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: attribute,
                        categoryYField: 'uniqueShowName',
                        maxWidth: am5.percent(10),
                        minWidth: am5.percent(2),
                    })
                );

                series.columns.template.setAll({
                    fill: colorPalette[index % colorPalette.length], // Use color from the palette
                    stroke: am5.color(0xFFFFFF),                    // White border
                    strokeWidth: 1,
                    tooltipText: `[bold]Show Name:[/] {dataItem.dataContext.showName}\n[bold]{name} Count[/]: {valueX}`, // Tooltip showing attribute value
                    tooltipY: am5.percent(90),
                });
                series.data.setAll(formatteddogShows);

                series.appear();
                legend.data.push(series);
            });
            
            // Ensure chart size fits all data
            const chartHeight = Math.max(formatteddogShows.length * 40, 500);
            setChartHeight(chartHeight);
            chart.set('height', chartHeight);

            chart.appear(1000, 100);

            return () => {
                root.dispose();
            };
        }
    }, [formatteddogShows]);
    console.log("formatteddogShows >> ",formatteddogShows);    
    console.log("loading >> ",loading);
    console.log("noResults >> ",noResults);

    return (
        <div style={{ height: { chartHeight } }}>
            {loading && !noResults &&
                <div className="loader" >
                    <ContentLoader
                        speed={1}
                        width={'100%'}
                        height={formatteddogShows.length > 0 ? chartHeight : 500} // Adjust the height based on the number of lines and gap
                        viewBox={`0 0 100% ${formatteddogShows.length > 0 ? chartHeight : 500}`}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        {Array.from({ length: 10 }, (_, index) => (
                            <rect
                                key={index}
                                x="0"
                                y={(70 + 4) * index}
                                rx="5"
                                ry="5"
                                width="100%"
                                height={formatteddogShows.length > 0 ? chartHeight : 500}
                            />
                        ))}
                    </ContentLoader>
                </div>
            }
            {!loading && (noResults || formatteddogShows.length === 0) &&
                <>
                    <div style={{ textAlign: 'center', marginTop: '70px' }}>
                        <strong style={{ fontSize: '20px' }}>{provinceName ? "Province - " + provinceName : ''}</strong>
                    </div>
                    <div id="chartdiv" ref={chartRef} style={{
                        width: '100%',
                        height: `${formatteddogShows.length > 0 ? chartHeight : 500}px`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative'
                    }}>
                        <p style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>No result found</p>
                    </div>
                </>
            }
            {!loading && !noResults && formatteddogShows.length > 0 &&
                <>
                    <div style={{ textAlign: 'center', marginTop: '100px' }}>
                        <strong style={{ fontSize: '20px' }}>{provinceName ? "Province - " + provinceName : ''}</strong>
                    </div>
                    <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: `${formatteddogShows.length > 0 ? chartHeight : 500}px` }}></div>
                </>
            }
        </div>
    );
};

export default React.memo(ShowLevelProvince);