export const GET_EVENT_DISPLAY_RESULT_FILES = "GET_EVENT_DISPLAY_RESULT_FILES";
export const GET_EVENT_DISPLAY_RESULT_FILES_SUCCESS = "GET_EVENT_DISPLAY_RESULT_FILES_SUCCESS";
export const GET_EVENT_DISPLAY_RESULT_FILES_FAIL = "GET_EVENT_DISPLAY_RESULT_FILES_FAIL";


export const ADD_DISPLAY_EVENT_FILE="ADD_DISPLAY_EVENT_FILE";
export const ADD_DISPLAY_EVENT_FILE_SUCCESS="ADD_DISPLAY_EVENT_FILE_SUCCESS";
export const ADD_DISPLAY_EVENT_FILE_FAILURE="ADD_DISPLAY_EVENT_FILE_FAILURE";

export const DEL_DISPLAY_EVENT_FILE="DEL_DISPLAY_EVENT_FILE";
export const DEL_DISPLAY_EVENT_FILE_SUCCESS="DEL_DISPLAY_EVENT_FILE_SUCCESS";
export const DEL_DISPLAY_EVENT_FILE_FAILURE="DEL_DISPLAY_EVENT_FILE_FAILURE";
