export const GET_DOGS = "GET_DOGS";
export const GET_DOGS_SUCCESS = "GET_DOGS_SUCCESS";
export const GET_DOGS_FAIL = "GET_DOGS_FAIL";

export const DEL_DOG="DEL_DOG";
export const DEL_DOG_SUCCESS="DEL_DOG_SUCCESS";
export const DEL_DOG_FAILURE="DEL_DOG_FAILURE";

export const EDIT_DOG="EDIT_DOG";
export const EDIT_DOG_SUCCESS="EDIT_DOG_SUCCESS";
export const EDIT_DOG_FAILURE="EDIT_DOG_FAILURE";

export const GET_BREED_GROUP = "GET_BREED_GROUP";
export const GET_BREED_GROUP_SUCCESS = "GET_BREED_GROUP_SUCCESS";
export const GET_BREED_GROUP_FAIL = "GET_BREED_GROUP_FAIL";

export const ADD_BREED_GROUP = "ADD_BREED_GROUP";
export const ADD_BREED_GROUP_SUCCESS = "ADD_BREED_GROUP_SUCCESS";
export const ADD_BREED_GROUP_FAIL = "ADD_BREED_GROUP_FAIL";

export const EDIT_BREED_GROUP = "EDIT_BREED_GROUP";
export const EDIT_BREED_GROUP_SUCCESS = "EDIT_BREED_GROUP_SUCCESS";
export const EDIT_BREED_GROUP_FAIL = "EDIT_BREED_GROUP_FAIL";

export const DEL_BREED_GROUP = "DEL_BREED_GROUP";
export const DEL_BREED_GROUP_SUCCESS = "DEL_BREED_GROUP_SUCCESS";
export const DEL_BREED_GROUP_FAIL = "DEL_BREED_GROUP_FAIL";

export const UPLOAD_GROUP_FILE = "UPLOAD_GROUP_FILE";
export const UPLOAD_GROUP_FILE_SUCCESS = "UPLOAD_GROUP_FILE_SUCCESS";
export const UPLOAD_GROUP_FILE_FAIL = "UPLOAD_GROUP_FILE_FAIL";
