import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./AppBar";
import Header from "./Header";
import SideBar from "./sidebar/SideBar";
import classNames from "classnames";
import { Container } from "reactstrap";
import Topbar from "./content/Topbar";
import React, { useState } from "react";

export const ProtectedLayout = () => {
  const { user,logout } = useAuth();
  const outlet = useOutlet();
  const [sidebarIsOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="App">
      <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} logout={logout} user={user}/>
      <Container toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} fluid className={classNames("content", { "is-open": sidebarIsOpen })}> 
        <Topbar toggleSidebar={toggleSidebar} />{outlet}
      </Container>
    </div>
  );
};
