import React from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import logo from '../../assets/ckc-logo.png';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SubMenu from "./SubMenu";
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const SideBar = ({ isOpen, toggle, logout,user }) => (
  <div className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <img src={logo} alt="Logo"  className="sidebar-logo" />
    </div>

  {user?.adminData?.role==="marketing user"?(
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        <p></p>
        <NavItem className="sidebar_menu_title" onClick={toggle}>
          <NavLink tag={Link} to={"/dashboard"} >
            <DashboardIcon className="sidebar_menu_icon" />Dashboard
          </NavLink>
        </NavItem>
        <SubMenu title="Certificates" icon={<CardMembershipIcon className="sidebar_menu_icon" />} items={submenus[2]} menuToggle={toggle}/>
        <NavItem className="sidebar_menu_title" onClick={toggle}>
          <NavLink tag={Link} to={"/dashboard/my-account"} >
            <PersonIcon className="sidebar_menu_icon" />My Account
          </NavLink>
        </NavItem>
      </Nav>
    </div>

    ):(
      <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        <p></p>
        <NavItem className="sidebar_menu_title" onClick={toggle}>
          <NavLink tag={Link} to={"/dashboard"} >
            <DashboardIcon className="sidebar_menu_icon" />Dashboard
          </NavLink>
        </NavItem>

        <SubMenu title="Top Dog Standings" icon={<EmojiEventsIcon className="sidebar_menu_icon" />} items={submenus[0]} menuToggle={toggle}/>

        <SubMenu title="Certificates" icon={<CardMembershipIcon className="sidebar_menu_icon" />} items={submenus[3]} menuToggle={toggle}/>

        <SubMenu title="Stats" icon={<BarChartIcon className="sidebar_menu_icon" />} items={submenus[4]} menuToggle={toggle}/>
        
        <NavItem  onClick={toggle} className="sidebar_menu_title">
          <NavLink tag={Link} to={"/dashboard/event-results-display"}>
            <EventIcon className="sidebar_menu_icon" />
            Event Results Display</NavLink>
        </NavItem>
        <SubMenu title="Manage" icon={<ManageAccountsIcon className="sidebar_menu_icon" />} items={submenus[1]} menuToggle={toggle}/>
    
      </Nav>
    </div>

    )}

  
  </div>
);

const submenus = [

  [
    {
      title: "All Traditional Event Files",
      target: "allEventFiles",
    },
    {
      title: "Overall Result",
      target: "overall-results",
    },
    {
      title: "Posting Dates",
      target: "posting-dates",
    },
    {
      title: "Public Urls",
      target: "public-urls",
    }
  ],
  [
    {
      title: "Users",
      target: "allUsers",
    },
    {
      title: "Dogs",
      target: "dogs",
    },
    {
      title: "Breeds Table",
      target: "allBreedsTable",
    },
    {
      title: "Digital Certificates",
      target: "digitalCertificates",
    },
    {
      title: "My Account",
      target: "my-account",
    },
  ],
  [
    {
      title:"COR Results",
      target:"cor-results"
    },
    {
      title:"All Certificates",
      target:"certificates"
    }
  ],
  [
    {
      title: "All COR Event Files",
      target: "allCORFiles",
    },
    {
      title:"COR Results",
      target:"cor-results"
    },
    {
      title:"All Certificates",
      target:"certificates"
    },
    {
      title:"Custom Certificate",
      target:"create-certificate"
    }
  ],
  [
    {
      title: "Year level",
      target: "year-level",
    },
    {
      title: "Group Level",
      target: "group-level",
    },
    {
      title:"Show level",
      target:"show-level"
    },
    // {
    //   title:"Judges",
    //   target:"judges"
    // },
  ]
];

export default SideBar;
