import { takeLatest, put, call } from "redux-saga/effects";

import { LOGIN,FORGOT_PASSWORD,RESET_PASSWORD,CHANGE_PASSWORD} from "./actionTypes";

import {
  loginSuccess,
  loginFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  changePasswordFailure,
  changePasswordSuccess
} from "./actions";

import { login,forgotPassword,resetPassword,changePassword } from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  function* onChangePassword({ payload: data,navigate,token }) {
    try {
      const response = yield call(changePassword,data,token);
      console.log(response);
      toast(response.data);
      if(response.statusCode===1){
        navigate("/dashboard/my-account");
      }
  
      yield put(changePasswordSuccess(response.data));
    } catch (error) {
      toast(error.response.data);
      yield put(changePasswordFailure(error.response));
    }
  }


  function* onResetPassword({ payload: data,navigate }) {
    try {
      const response = yield call(resetPassword,data);
      toast(response.data);
      if(response.statusCode===1){
        navigate("/login");
      }

      yield put(resetPasswordSuccess(response));
    } catch (error) {
    toast(error.response.data.message);
      yield put(resetPasswordFailure(error.response));
    }
  }

function* onForgotPassword({ payload: data,navigate }) {
  try {
    const response = yield call(forgotPassword,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate("/login");

       }

    yield put(forgotPasswordSuccess(response));
  } catch (error) {
  toast(error.response.data.message);
    yield put(forgotPasswordFailure(error.response));
  }
}

function* onLogin({ payload: data,authLogin }) {
  try {
    const response = yield call(login,data);
    console.log(response.data.data.admin);
    if(response.data.statusCode===200){
      authLogin({
            email: response.data.data.admin.email,
            adminData:response.data.data.admin,
            token:response.headers.authorization
          });
       }

    yield put(loginSuccess(response.data));
  } catch (error) {
  toast(error.response.data.data);
    yield put(loginFailure(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(LOGIN, onLogin);
  yield takeLatest(FORGOT_PASSWORD, onForgotPassword);
  yield takeLatest(RESET_PASSWORD, onResetPassword);
  yield takeLatest(CHANGE_PASSWORD, onChangePassword);



}

export default CartSaga;
