export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";


export const ADD_EVENT="ADD_EVENT";
export const ADD_EVENT_SUCCESS="ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE="ADD_EVENT_FAILURE";

export const GET_EVENT_ATTENDEE = "GET_EVENT_ATTENDEE";
export const GET_EVENT_ATTENDEE_SUCCESS = "GET_EVENT_ATTENDEE_SUCCESS";
export const GET_EVENT_ATTENDEE_FAIL = "GET_EVENT_ATTENDEE_FAIL";


export const GET_EVENT_RESULT = "GET_EVENT_RESULT";
export const GET_EVENT_RESULT_SUCCESS = "GET_EVENT_RESULT_SUCCESS";
export const GET_EVENT_RESULT_FAIL = "GET_EVENT_RESULT_FAIL";


export const CALCULATE_EVENT_RESULT="CALCULATE_EVENT_RESULT";
export const CALCULATE_EVENT_RESULT_SUCCESS="CALCULATE_EVENT_RESULT_SUCCESS";
export const CALCULATE_EVENT_RESULT_FAILURE="CALCULATE_EVENT_RESULT_FAILURE";




export const GET_EVENT_LIST = "GET_EVENT_LIST";
export const GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS";
export const GET_EVENT_LIST_FAIL = "GET_EVENT_LIST_FAIL";



export const GET_EVENT_PUBLIC_RESULT = "GET_EVENT_PUBLIC_RESULT";
export const GET_EVENT_PUBLIC_RESULT_SUCCESS = "GET_EVENT_PUBLIC_RESULT_SUCCESS";
export const GET_EVENT_PUBLIC_RESULT_FAIL = "GET_EVENT_PUBLIC_RESULT_FAIL";


export const DEL_EVENT_FILE="DEL_EVENT_FILE";
export const DEL_EVENT_FILE_SUCCESS="DEL_EVENT_FILE_SUCCESS";
export const DEL_EVENT_FILE_FAILURE="DEL_EVENT_FILE_FAILURE";

export const CALCULATE_OVERALL_RESULT="CALCULATE_OVERALL_RESULT";
export const CALCULATE_OVERALL_RESULT_SUCCESS="CALCULATE_OVERALL_RESULT_SUCCESS";
export const CALCULATE_OVERALL_RESULT_FAILURE="CALCULATE_OVERALL_RESULT_FAILURE";

export const GET_OVERALL_RESULT = "GET_OVERALL_RESULT";
export const GET_OVERALL_RESULT_SUCCESS = "GET_OVERALL_RESULT_SUCCESS";
export const GET_OVERALL_RESULT_FAIL = "GET_OVERALL_RESULT_FAIL";


export const GET_CLUB_WISE_RANKING = "GET_CLUB_WISE_RANKING";
export const GET_CLUB_WISE_RANKING_SUCCESS = "GET_CLUB_WISE_RANKING_SUCCESS";
export const GET_CLUB_WISE_RANKING_FAIL = "GET_CLUB_WISE_RANKING_FAIL";


export const GET_DOG_RESULT = "GET_DOG_RESULT";
export const GET_DOG_RESULT_SUCCESS = "GET_DOG_RESULT_SUCCESS";
export const GET_DOG_RESULT_FAIL = "GET_DOG_RESULT_FAIL";
export const DOWNLOAD_CERTIFICATE = "DOWNLOAD_CERTIFICATE";
export const DOWNLOAD_CERTIFICATE_SUCCESS = "DOWNLOAD_CERTIFICATE_SUCCESS";
export const DOWNLOAD_CERTIFICATE_FAIL = "DOWNLOAD_CERTIFICATE_FAIL";

export const GET_FILE_RESULTS = "GET_FILE_RESULTS";
export const GET_FILE_RESULTS_SUCCESS = "GET_FILE_RESULTS_SUCCESS";
export const GET_FILE_RESULTS_FAIL = "GET_FILE_RESULTS_FAIL";

export const DOWNLOAD_LETTER = "DOWNLOAD_LETTER";
export const DOWNLOAD_LETTER_SUCCESS = "DOWNLOAD_LETTER_SUCCESS";
export const DOWNLOAD_LETTER_FAIL = "DOWNLOAD_LETTER_FAIL";

export const SEND_CERTIFICATE = "SEND_CERTIFICATE";
export const SEND_CERTIFICATE_SUCCESS = "SEND_CERTIFICATE_SUCCESS";
export const SEND_CERTIFICATE_FAIL = "SEND_CERTIFICATE_FAIL";

export const SEND_LETTER = "SEND_LETTER";
export const SEND_LETTER_SUCCESS = "SEND_LETTER_SUCCESS";
export const SEND_LETTER_FAIL = "SEND_LETTER_FAIL";

export const GET_BATCH_RESULT = "GET_BATCH_RESULT";
export const GET_BATCH_RESULT_SUCCESS = "GET_BATCH_RESULT_SUCCESS";
export const GET_BATCH_RESULT_FAIL = "GET_BATCH_RESULT_FAIL";

export const BATCH_PRINT = "BATCH_PRINT";
export const BATCH_PRINT_SUCCESS = "BATCH_PRINT_SUCCESS";
export const BATCH_PRINT_FAIL = "BATCH_PRINT_FAIL";

export const ADD_COR_EVENT="ADD_COR_EVENT";
export const ADD_COR_EVENT_SUCCESS="ADD_COR_EVENT_SUCCESS";
export const ADD_COR_EVENT_FAILURE="ADD_COR_EVENT_FAILURE";

export const GET_COR_RESULT = "GET_COR_RESULT";
export const GET_COR_RESULT_SUCCESS = "GET_COR_RESULT_SUCCESS";
export const GET_COR_RESULT_FAIL = "GET_COR_RESULT_FAIL";

export const CUSTOM_CERTIFICATE = "CUSTOM_CERTIFICATE";
export const CUSTOM_CERTIFICATE_SUCCESS = "CUSTOM_CERTIFICATE_SUCCESS";
export const CUSTOM_CERTIFICATE_FAIL = "CUSTOM_CERTIFICATE_FAIL";

export const DIGITAL_CERTIFICATE = "DIGITAL_CERTIFICATE";
export const DIGITAL_CERTIFICATE_SUCCESS = "DIGITAL_CERTIFICATE_SUCCESS";
export const DIGITAL_CERTIFICATE_FAIL = "DIGITAL_CERTIFICATE_FAIL";
