export const FETCH_YEAR_LEVEL_STATS = 'FETCH_YEAR_LEVEL_STATS';
export const FETCH_YEAR_LEVEL_STATS_SUCCESS = 'FETCH_YEAR_LEVEL_STATS_SUCCESS';
export const FETCH_YEAR_LEVEL_STATS_FAIL = 'FETCH_YEAR_LEVEL_STATS_FAIL';

export const FETCH_SHOW_LEVEL_STATS = 'FETCH_SHOW_LEVEL_STATS';
export const FETCH_SHOW_LEVEL_STATS_SUCCESS = 'FETCH_SHOW_LEVEL_STATS_SUCCESS';
export const FETCH_SHOW_LEVEL_STATS_FAIL = 'FETCH_SHOW_LEVEL_STATS_FAIL';

export const FETCH_SHOW_LEVEL_SHOWS_STATS = 'FETCH_SHOW_LEVEL_SHOWS_STATS';
export const FETCH_SHOW_LEVEL_SHOWS_STATS_SUCCESS = 'FETCH_SHOW_LEVEL_SHOWS_STATS_SUCCESS';
export const FETCH_SHOW_LEVEL_SHOWS_STATS_FAIL = 'FETCH_SHOW_LEVEL_SHOWS_STATS_FAIL';