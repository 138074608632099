import { Button, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowLevelShowsStats, fetchShowLevelStats } from '../../store/stats/actions';
import { useAuth } from '../../hooks/useAuth';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { debounce } from 'lodash';
import { Box, CircularProgress } from '@mui/material';
import ContentLoader from 'react-content-loader';
import axios from 'axios';
import { SHOW_LEVEL_STATS } from "../../helpers/url_helper";
import ShowLevelProvince from './ShowLevelProvince'
import { PieChart, PieSeries } from '@amcharts/amcharts5/percent';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated';

const provinces = [
    { value: '1', label: 'Alberta' },
    { value: '2', label: 'BC' },
    { value: '3', label: 'Manitoba' },
    { value: '4', label: 'New Brunswick' },
    { value: '5', label: 'Newfoundland' },
    { value: '6', label: 'North West Territories' },
    { value: '7', label: 'Nova Scotia' },
    { value: '8', label: 'Nunavut' },
    { value: '9', label: 'Ontario' },
    { value: '10', label: 'Prince Edward Island' },
    { value: '11', label: 'Quebec' },
    { value: '12', label: 'Saskatchewan' },
    { value: '13', label: 'Yukon' }
];

const currentYear = new Date().getFullYear();
const years = [...Array(currentYear - 2000 + 1)].map((_, index) => (currentYear - index).toString());

const findProvinceData = (groupedProvinceResults, province) => {
    const result = {};

    for (const year in groupedProvinceResults) {
        if (groupedProvinceResults.hasOwnProperty(year)) {
            if (groupedProvinceResults[year][province] && groupedProvinceResults[year][province].length > 0) {
                result[year] = {
                    totalshows: groupedProvinceResults[year][province].length,  // Total number of shows
                    shows: groupedProvinceResults[year][province]  // Array of shows
                };
            }
        }
    }

    return result;
};

const findShowsFromProvinceData = (provinceData) => {
    const transformedData = [];

    for (const year in provinceData) {
        if (provinceData.hasOwnProperty(year)) {
            const shows = provinceData[year].shows;

            shows.forEach(show => {
                transformedData.push({
                    dogShowId: show.DogShow_ID,
                    showName: show.ShowName,
                    ...show,
                });
            });
        }
    }

    return transformedData;
};

function ShowLevel() {
    const dispatch = useDispatch();
    const { user, logout } = useAuth();
    const [startYear, setStartYear] = useState(2011);
    const [endYear, setEndYear] = useState(2011);
    const [dogShows, setDogShows] = useState([]);
    const [province, setProvince] = useState(1);
    const [selectedDogShowId, setSelectedDogShowId] = useState(null);
    const chartProvinceRef = useRef(null);
    const chartShowsRef = useRef(null);
    const [noResults, setNoResults] = useState(false);
    // const [dogShowResData, setDogShowResData] = useState([]);
    // const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    // Handle dropdown changes and API call
    const handleDropdownChange = () => {
        // const selectedShow = dogShows.find(p => p.dogShowId === String(selectedDogShowId));
        // setShowLabel(selectedShow ? selectedShow.showName : '');

        if (startYear && endYear && parseInt(startYear) > parseInt(endYear)) {
            toast.error('Start Year must be less than or equal to End Year.');
            return;
        }

        let payload = {};
        if (startYear) payload.startYear = startYear;
        if (endYear) payload.endYear = endYear;

        // Dispatch the API call if valid data is present
        if (Object.keys(payload).length > 0) {
            dispatch(fetchShowLevelStats(payload, user.token));
        }
        else {
            // Optional: If all dropdowns are empty, you can fetch data without filters
            dispatch(fetchShowLevelStats({}, user.token));
        }

    };

    // call API whenever any dropdown value changes
    useEffect(() => {
        // if (initialDataLoaded) {
        handleDropdownChange();
        // }
        // initialDataLoaded
    }, [startYear, endYear]);

    useEffect(() => {
        let payload = {};
        if (startYear) payload.startYear = startYear;
        if (endYear) payload.endYear = endYear;
        if (province) payload.province = province;
        if (selectedDogShowId) payload.dogShowId = selectedDogShowId;

        if (Object.keys(payload).length > 0) {
            dispatch(fetchShowLevelShowsStats(payload, user.token));
        }
    }, [selectedDogShowId]);

    //get the responce of api from redux store
    const showLevelStats = useSelector((state) => {
        return state?.statsReducer?.showLevelStateResponse || []
    });

    const loading = useSelector((state) => {
        return state?.statsReducer?.loading || null;
    });

    const showLevelShowsData = useSelector((state) => {
        // console.log("showLevelShowsData >> state >> ", state)
        return state?.statsReducer?.showLevelShowsStateResponse || []
    });

    const loadingShowsData = useSelector((state) => {
        return state?.statsReducer?.loadingShows;
    });

    const groupedProvinceResults = showLevelStats.reduce((acc, item) => {
        const year = item.TheYear;
        const province = provinces.find(prov => prov.value === item.Province_ID);

        if (!province) return acc;

        if (!acc[year]) {
            acc[year] = {};
        }

        if (!acc[year][province.label]) {
            acc[year][province.label] = 0;
            acc[year][province.value] = [];
        }

        acc[year][province.label] += 1;
        acc[year][province.value].push(item);

        return acc;
    }, {});

    // console.log("groupedProvinceResults >> ", groupedProvinceResults)

    // Transform the aggregated data into the format required for the chart
    const formattedShowLevelStats = Object.keys(groupedProvinceResults).map(year => {
        const provincesData = groupedProvinceResults[year];
        const yearData = {
            TheYear: year,
            ...provinces.reduce((provincesAcc, province) => {
                provincesAcc[province.label] = provincesData[province.label] || 0;
                return provincesAcc;
            }, {})
        };
        return yearData;
    });
    // console.log("formattedShowLevelStats >> ", formattedShowLevelStats);
    const provinceData = findProvinceData(groupedProvinceResults, province);
    // console.log("provinceData >> ", provinceData);
    const showsdata = findShowsFromProvinceData(provinceData);
    // console.log("showsdata >> ", showsdata);

    const totalSumOfShows = formattedShowLevelStats.length > 0 ? Object.values(formattedShowLevelStats[0])
        .filter(value => typeof value === 'number')
        .reduce((acc, value) => acc + value, 0) : 0;

    useEffect(() => {
        setDogShows(prevShows => {
            if (JSON.stringify(prevShows) !== JSON.stringify(showsdata)) {
                return showsdata;
            }
            return prevShows;
        });

        if (showsdata.length > 0 && (selectedDogShowId === null || !showsdata.find(show => show.dogShowId === selectedDogShowId))) {
            setSelectedDogShowId(showsdata[0].dogShowId);
        }
    }, [showsdata]);

    // Check for no results
    useEffect(() => {
        setNoResults(formattedShowLevelStats.length === 0 && !loading);
    }, [formattedShowLevelStats, loading]);

    useEffect(() => {
        if (chartProvinceRef.current && formattedShowLevelStats.length > 0) {
            const aggregatedData = {};

            formattedShowLevelStats.forEach(yearData => {
                Object.keys(yearData).forEach(provinceName => {
                    if (provinceName !== "TheYear") {
                        if (!aggregatedData[provinceName]) {
                            aggregatedData[provinceName] = 0;
                        }
                        aggregatedData[provinceName] += yearData[provinceName];
                    }
                });
            });

            // Convert aggregated data into format suitable for the pie chart
            const chartData = Object.keys(aggregatedData).map(provinceName => ({
                sector: provinceName,
                size: aggregatedData[provinceName]
            }));

            // Create root element
            const root = am5.Root.new(chartProvinceRef.current);

            // Set themes
            root.setThemes([AnimatedTheme.new()]);

            // Create chart
            const chart = root.container.children.push(PieChart.new(root, {
                innerRadius: 0,
                layout: root.verticalLayout
            }));

            // Create series
            const series = chart.series.push(PieSeries.new(root, {
                valueField: "size",
                categoryField: "sector",
                radius: am5.percent(60),
                // alignLabels: true
            }));

            // Set distinct colors for each slice using series' color set
            series.get("colors").set("colors", [
                am5.color(0xFF5733), // Red
                am5.color(0x33FF57), // Green
                am5.color(0x3357FF), // Blue
                am5.color(0xFF33FF), // Pink
                am5.color(0xFFFF33), // Yellow
                am5.color(0xFF33A1), // Magenta
                am5.color(0xFF4500), // Orange-Red
                am5.color(0x40E0D0), // Turquoise
                am5.color(0xDA70D6), // Orchid
                am5.color(0x6A5ACD), // Slate Blue
                am5.color(0xDAA520), // Golden Rod
                am5.color(0xDC143C), // Crimson
                am5.color(0x2E8B57), // Sea Green
                am5.color(0x8A2BE2), // Blue Violet
                am5.color(0xFF6347), // Tomato
                am5.color(0x4682B4), // Steel Blue
                am5.color(0xD2691E), // Chocolate
                am5.color(0xFF1493), // Deep Pink
                am5.color(0x00FA9A), // Medium Spring Green
                am5.color(0xFF8C00), // Dark Orange
                am5.color(0x7FFF00), // Chartreuse
            ]);

            // Add white borders to each slice
            series.slices.template.setAll({
                stroke: am5.color(0xFFFFFF),  // White border
                strokeWidth: 1,  // Border width
            });

            // Set data
            series.data.setAll(chartData);

            series.labels.template.setAll({
                text: "{category}",
                fill: am5.color(0x000000),
                fontSize: 14
            });

            // Remove outer labels
            // series.labels.template.set("visible", true);

            // Add hover tooltips to show total shows count
            series.slices.template.set("tooltipText", "[bold]{category}[/]\n[bold]Total Shows:[/] {value}");

            // Add label
            const label = root.tooltipContainer.children.push(am5.Label.new(root, {
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50,
                fill: am5.color(0x000000),
                fontSize: 18,
                // text: `${startYear}`
            }));

            return () => {
                root.dispose();
            };
        }
    }, [formattedShowLevelStats]);

    const selectedShow = dogShows.find(show => show.dogShowId === selectedDogShowId)?.showName || '';

    useEffect(() => {
        if (chartShowsRef.current && !!selectedShow && formattedShowLevelStats.length > 0 && !loadingShowsData && showLevelShowsData.length > 0) {
            const aggregatedData = showLevelShowsData.reduce((acc, show) => {
                if (!acc[show.ID_NO]) {
                    acc[show.ID_NO] = {
                        ID_NO: show.ID_NO,
                        ShowName: show.ShowName,
                        Groups_ID: show.Groups_ID,
                        SpecialtyType: show.SpecialtyType,
                        GroupName: show.GroupName,
                        NumOfDogs: show.NumOfDogs,
                        ResultType: show.ResultType,
                        size: 0
                    };
                }
                acc[show.ID_NO].size += parseInt(show.NumOfDogs, 10);
                return acc;
            }, {});

            // Convert aggregated data into format suitable for the pie chart
            const chartData = Object.keys(aggregatedData).map(id => ({
                ID_NO: id,
                ...aggregatedData[id]
            }));

            // Create root element for the shows pie chart
            const root = am5.Root.new(chartShowsRef.current);
            root.setThemes([AnimatedTheme.new()]);

            // Create chart
            const chart = root.container.children.push(PieChart.new(root, {
                innerRadius: 0,
                layout: root.verticalLayout
            }));

            // Create series
            const series = chart.series.push(PieSeries.new(root, {
                valueField: 'size',
                categoryField: 'ShowName',
                radius: am5.percent(60),
                // alignLabels: false
            }));

            // Set distinct colors for each slice using series' color set
            series.get("colors").set("colors", [
                am5.color(0x4682B4), // Steel Blue
                am5.color(0xFF5733), // Red
                am5.color(0x33FF57), // Green
                am5.color(0x3357FF), // Blue
                am5.color(0xFF33FF), // Pink
                am5.color(0xFFFF33), // Yellow
                am5.color(0xFF33A1), // Magenta
                am5.color(0xFF4500), // Orange-Red
                am5.color(0x40E0D0), // Turquoise
                am5.color(0xDA70D6), // Orchid
                am5.color(0x6A5ACD), // Slate Blue
                am5.color(0xDAA520), // Golden Rod
                am5.color(0xDC143C), // Crimson
                am5.color(0x2E8B57), // Sea Green
                am5.color(0x8A2BE2), // Blue Violet
                am5.color(0xFF6347), // Tomato
                am5.color(0xD2691E), // Chocolate
                am5.color(0xFF1493), // Deep Pink
                am5.color(0x00FA9A), // Medium Spring Green
                am5.color(0xFF8C00), // Dark Orange
                am5.color(0x7FFF00), // Chartreuse
            ]);

            // Add white borders to each slice
            series.slices.template.setAll({
                stroke: am5.color(0xFFFFFF),
                strokeWidth: 1,
            });

            // Set data
            series.data.setAll(chartData);

            series.labels.template.setAll({
                text: "Dogs Participated: {NumOfDogs}",
                fill: am5.color(0x000000),
                fontSize: 14
            });

            // Remove outer labels
            // series.labels.template.set('visible', true);

            // Add hover tooltips to show detailed information
            series.slices.template.set('tooltipText',
                "[bold]Show Name:[/] {ShowName}\n" +
                "[bold]Group Name:[/] {GroupName}\n" +
                "[bold]Number of Dogs:[/] {NumOfDogs}"
            );

            // Add label
            const label = root.tooltipContainer.children.push(am5.Label.new(root, {
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50,
                fill: am5.color(0x000000),
                fontSize: 18,
                // text: `${selectedShow}`
            }));

            return () => {
                root.dispose();
            };
        }
    }, [loadingShowsData, showLevelShowsData, selectedShow]);

    return (
        <div style={{ backgroundColor: '#F1F2F4' }}>
            <p className='page-heading'>
                Shows Level Stats
            </p>

            <ToastContainer />

            <div className='page-body'>
                <Card className="my-2" style={{ padding: 0 }}>
                    <CardHeader>
                        <Form>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input
                                        id="startYear"
                                        name="startYear"
                                        type="select"
                                        value={startYear}
                                        onChange={(e) => { setStartYear(e.target.value); setEndYear(e.target.value) }}
                                        required
                                    >
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>

                                {/* <Col sm={2}>
                                    <Input
                                        id="endYear"
                                        name="endYear"
                                        type="select"
                                        value={endYear}
                                        onChange={(e) => setEndYear(e.target.value)}
                                        required
                                    >
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </Col> */}

                                <Col sm={3}>
                                    <Input
                                        id="province"
                                        name="province"
                                        type="select"
                                        value={province}
                                        onChange={(e) => setProvince(e.target.value)}
                                        required
                                    >
                                        {provinces.map((prov) => (
                                            <option key={prov.value} value={prov.value}>
                                                {prov.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="dogShow"
                                        name="dogShow"
                                        type="select"
                                        value={selectedDogShowId}
                                        onChange={(e) => setSelectedDogShowId(e.target.value)}
                                        required
                                    >
                                        {/* <option value="">Select Dog Show</option> */}
                                        {dogShows.map(show => (
                                            <option key={show.dogShowId} value={show.dogShowId}>
                                                {show.showName}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardHeader>
                    <CardBody>
                        {loading && !noResults && loadingShowsData &&
                            <div className="loader" >
                                <ContentLoader
                                    speed={1}
                                    width={'100%'}
                                    height={500} // Adjust the height based on the number of lines and gap
                                    viewBox={`0 0 100% ${500}`}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    {Array.from({ length: 10 }, (_, index) => (
                                        <rect
                                            key={index}
                                            x="0"
                                            y={(70 + 4) * index}
                                            rx="5"
                                            ry="5"
                                            width="100%"
                                            height="500"
                                        />
                                    ))}
                                </ContentLoader>
                            </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ width: '48%', textAlign: 'center' }}>
                                {!loading && noResults &&
                                    <>
                                        <strong style={{ fontSize: '20px' }} >{startYear} - Total Shows : {totalSumOfShows}</strong>
                                        <div id="chartdiv" ref={chartProvinceRef} style={{
                                            width: '100%',
                                            height: '500px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative'
                                        }}>
                                            <p style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                                No result found</p>
                                        </div>
                                    </>
                                }

                                {!loading && !noResults &&
                                    <>
                                        <strong style={{ fontSize: '20px' }} >{startYear} - Total Shows : {totalSumOfShows}</strong>
                                        <div ref={chartProvinceRef} id="provincechartdiv" style={{ height: '500px' }} />
                                    </>
                                }
                            </div>
                            <div style={{ width: '48%', textAlign: 'center' }}>
                                {!(!!selectedShow) && !loading && !loadingShowsData &&
                                    <>
                                        <strong style={{ fontSize: '20px' }} >{!!selectedShow ? `Show - ` + selectedShow : 'No Shows'}</strong>
                                        <div ref={chartShowsRef} id="showschartdiv" style={{
                                            width: '100%',
                                            height: '500px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative'
                                        }}>
                                            <p style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}>No result found</p>
                                        </div>
                                    </>
                                }
                                {!!selectedShow && !loadingShowsData && formattedShowLevelStats.length > 0 && showLevelShowsData.length > 0 &&
                                    <>
                                        <strong style={{ fontSize: '20px' }} >Show - {selectedShow}</strong>
                                        <div ref={chartShowsRef} id="showschartdiv" style={{ height: '500px' }} />
                                    </>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <ShowLevelProvince provinceName={provinces[province - 1].label} dogShows={showLevelStats.length > 0 ? dogShows : []} loading={loading} 
                            noResults={noResults} 
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default ShowLevel;
