export const buildStatsApiUrl = (baseUrl, params) => {
    const url = new URL(baseUrl);
    const searchParams = new URLSearchParams();

    if (params.startYear) searchParams.append('startYear', params.startYear);
    if (params.endYear) searchParams.append('endYear', params.endYear);
    if (params.province) searchParams.append('province', params.province);
    if (params.dogShowId) searchParams.append('dogShowId', params.dogShowId);

    url.search = searchParams.toString();
    return url.toString();
};