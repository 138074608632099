import { Row, Button } from 'reactstrap';
import React from 'react';
import { Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { createCustomCertificate } from '../../store/events/actions';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useAuth } from '../../hooks/useAuth';
import { Circles } from "react-loader-spinner";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

function CreateCertificate() {

  const { user, logout } = useAuth();
  const [iframeKey, setIframeKey] = useState(0);
  const [certData, setCertData] = useState(0);
  const [certificateUrl, setCertificateUrl] = useState('');
  const { loadingCustomCertificate, customCertificateData } = useSelector((state) => state.EventsReducer);

  let dispatch = useDispatch();

  useEffect(() => {
    const appUrl = process.env.REACT_APP_APP_URL;
    const pdfUrl = appUrl+'getCustomCertificate/';
    if (customCertificateData.certificateId && certData) {
      const url = pdfUrl + customCertificateData.certificateId;
      setCertificateUrl(url);
    }
    else{
      const url = pdfUrl + 'Blank';
      setCertificateUrl(url);
    }
    if(!loadingCustomCertificate){
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [customCertificateData,loadingCustomCertificate]);

  const createCertificate = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const certificateData = {
      certificateTitle: data.get("certificateTitle").toUpperCase(),
      dogTitle: data.get("dogTitle").toUpperCase(),
      ranking: data.get("dogRank"),
      award: data.get("award").toUpperCase(),
      eventYear: data.get("eventYear"),
    }
    dispatch(createCustomCertificate(certificateData, user.token));
    setCertData(1);
  };


  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Custom Certificate</p>
      <div className='page-body' >
        <Row>
          <Col md="8">
            {loadingCustomCertificate ? (
              <div className="loader" >
                <ContentLoader 
                  speed={1}
                  style={{ width: '100%', height: '100%' }}
                  viewBox="0 0 700 500"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#cecccc"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                </ContentLoader>
              </div>
            ) : (
            <iframe
              key={iframeKey}
              title="Certificate"
              src={certificateUrl}
              width="100%"
              height="630px"
              allowFullScreen
            ></iframe>
            )}
          </Col>
          <Col md="4">
            <h5>Certificate Details</h5>
            <hr></hr>
            <CardBody>
              <Form onSubmit={createCertificate}>
                <FormGroup row>
                  <Label for="certificateTitle" sm={5}> Certificate Title </Label>
                  <Col sm={7}>
                    <Input id="certificateTitle" name="certificateTitle" placeholder="Enter Certificate Title" type="text" pattern="[^\d]+" title="Only letters are allowed" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="dogTitle" sm={5}> Dog Title </Label>
                  <Col sm={7}>
                    <Input id="dogTitle" name="dogTitle" placeholder="Enter Dog Title" type="text" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="dogRank" sm={5}> Dog Rank </Label>
                  <Col sm={7}>
                    <Input id="dogRank" name="dogRank" placeholder="Enter Dog Rank" type="number" pattern="[0-9]*" title="Only numbers are allowed" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="award" sm={5}> Dog Breed/Group </Label>
                  <Col sm={7}>
                    <Input id="award" name="award" placeholder="Enter Dog Breed or Group" type="text" pattern="[^\d]+" title="Only letters are allowed" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="eventYear" sm={5}> Event Year </Label>
                  <Col sm={7}>
                    <Input id="eventYear" name="eventYear" placeholder="Enter Year" type="number" pattern="[0-9]*" title="Only numbers are allowed" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={{ offset: 5, size: 6}}>
                    <Button color='primary' outline type='submit'>
                      {loadingCustomCertificate ? ('Please wait...') : ('Generate')}
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CreateCertificate;
