// reducers.js
import {
    FETCH_YEAR_LEVEL_STATS_SUCCESS,
    FETCH_YEAR_LEVEL_STATS_FAIL,
    FETCH_YEAR_LEVEL_STATS,
    FETCH_SHOW_LEVEL_STATS,
    FETCH_SHOW_LEVEL_STATS_SUCCESS,
    FETCH_SHOW_LEVEL_STATS_FAIL,
    FETCH_SHOW_LEVEL_SHOWS_STATS,
    FETCH_SHOW_LEVEL_SHOWS_STATS_SUCCESS,
    FETCH_SHOW_LEVEL_SHOWS_STATS_FAIL,
} from './actionTypes';

const initialState = {
    error: null,
    loading: false,
    loadingShows: false,
    yearLevelStateResponse: [],
    showLevelStateResponse: [],
    showLevelShowsStateResponse: [],
};

const statsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_YEAR_LEVEL_STATS:
            return {
                ...state,
                loading: true,
            };
        case FETCH_YEAR_LEVEL_STATS_SUCCESS:
            return {
                ...state,
                yearLevelStateResponse: action.payload.response?.results||[],
                loading: false,
            };
        case FETCH_YEAR_LEVEL_STATS_FAIL:
            return {
                ...state,
                error: action.payload,
                yearLevelStateResponse:[],
                loading: false,
            };
        case FETCH_SHOW_LEVEL_STATS:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SHOW_LEVEL_STATS_SUCCESS:
            return {
                ...state,
                showLevelStateResponse: action.payload.response?.results||[],
                loading: false,
            };
        case FETCH_SHOW_LEVEL_STATS_FAIL:
            return {
                ...state,
                error: action.payload,
                showLevelStateResponse: [],
                loading: false,
            };
        case FETCH_SHOW_LEVEL_SHOWS_STATS:
            return {
                ...state,
                loadingShows: true,
            };
        case FETCH_SHOW_LEVEL_SHOWS_STATS_SUCCESS:
            return {
                ...state,
                showLevelShowsStateResponse: action.payload.response?.results||[],
                loadingShows: false,
            };
        case FETCH_SHOW_LEVEL_SHOWS_STATS_FAIL:
            return {
                ...state,
                error: action.payload,
                showLevelShowsStateResponse: [],
                loadingShows: false,
            };
        default:
            return state;
    }
};

export default statsReducer;
