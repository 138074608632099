import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import {useLocation} from "react-router-dom";
import {changePassword} from "../store/adminLogin/actions";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate } from "react-router-dom";


export const ChangePassword = () => {
  const { login,user } = useAuth();
  const navigate = useNavigate();

  const { loadingChangePassword,changePasswordResponse } = useSelector((state) => state.LoginReducer);
  

    let dispatch = useDispatch();

      const handleSubmit = async (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);



        if(data.get("password")===data.get("confirmPassword")){
          const UpdatePasswordData={
            "email":user.adminData.email,
            "oldPassword":data.get("currentPassword"),
            "newPassword":data.get("password"),
        };
        console.log("reset password data",UpdatePasswordData);

         dispatch(changePassword(UpdatePasswordData,navigate,user.token));
      }else{
        toast("New Password and Confirm New Password should be same.");
      }
      
  };
 

  return (
    <Container component="main" maxWidth="xs">

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >

        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Your Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
            margin="normal"
            required
            fullWidth
            name="currentPassword"
            label="Current Password"
            type="password"
            id="currentPassword"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >{loadingChangePassword  ? (
            'Loading...'
                 ) : (
           'Change Password'
                   )}
          </Button>
          <ToastContainer />

        </Box>
      </Box>

    </Container>
  );
};
