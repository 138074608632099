import { combineReducers } from "redux";

import LoginReducer from "./adminLogin/reducer";
import EventsReducer from "./events/reducer";
import DogsReducer from "./dogs/reducer";
import PostingDatesReducer from "./postingDates/reducer";
import UsersReducer from "./users/reducer";
import EventResultsDisplayReducer from "./eventResultsDisplay/reducer";
import statsReducer from "./stats/reducer";

const rootReducer = combineReducers({
  LoginReducer,
  EventsReducer,
  DogsReducer,
  PostingDatesReducer,
  UsersReducer,
  EventResultsDisplayReducer,
  statsReducer
});

export default rootReducer;
