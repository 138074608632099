import { Button, Form, FormGroup, Label, Input, Row, Badge,InputGroup } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Card, CardHeader, Alert, CardBody } from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {  getPostingDates,savePostingDates } from '../store/postingDates/actions';
import "../styles.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import LaunchIcon from '@mui/icons-material/Launch';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ContentLoader from "react-content-loader";

function PostingDates() {

  let allClubs = [];
  const navigate = useNavigate();
  const { error, loading, postingDatesList,loadingSavePostingDate,savePostingDatesResponse} = useSelector((state) => state.PostingDatesReducer);
  const location = useLocation();
  const { user, logout } = useAuth();
  const [year, setYear] = useState(new Date().getFullYear().toString());


  if(!loading&&error.message==="Unauthorised Access"){
    logout();
  }

  const columns = useMemo(() => [
    {
      name: 'Year',
      selector: row => row.year,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Last Updated Date',
      selector: row => row.lastUpdatedDate,
      sortable: true,
      wrap: true,
      center:true,

    },
    {
      name: 'Results Current To Date',
      selector: row => row.resultsCurrentToDate,
      sortable: true,
      wrap: true,
      center:true,
    },
   
  ], []);


  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostingDates(user.token));
  
  }, [savePostingDatesResponse]);


  const saveChanges = async (event) => {

    event.preventDefault();


    const data = new FormData(event.currentTarget);

    const postingDatesData = {
      "year": year,
      "lastUpdatedDate": data.get("lastUpdatedDate"),
      "resultsCurrentToDate": data.get("resultsCurrentToDate"),
  
    };
    console.log("editDogData", postingDatesData);
    dispatch(savePostingDates(postingDatesData, user.token));

  };

 


  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <div className='page-heading'>

        <Row>
          <Col sm="3">
            Posting Dates
          </Col>
          <Col sm="2"></Col>
        
        </Row>
      </div>


      <ToastContainer />

      <div className='page-body'>

        <Card
          className="my-2"
          style={{ padding: 0 }}

        >
          <CardHeader>
          <Row>
          <Form onSubmit={saveChanges}>

            <InputGroup sm={12} >
              {/* <Input
                id="eventYear"
                name="eventYear"
                type="select"
                onChange={(e) => setYear(e.target.value)}
                required
              >
                <option >2022</option>
                <option selected>2023</option>
              </Input> */}
              <Input id="eventYear" name="eventYear" type="select" onChange={(e) => setYear(e.target.value)} required>
                {[...Array(3)].map((_, index) => {
                  const year = new Date().getFullYear() - index;
                  return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                })}
              </Input>

              <Input
                id="lastUpdatedDate"
                name="lastUpdatedDate"
                type="text"
                required
                placeholder='Last Updated Date'
              />
                     <Input
                id="resultsCurrentToDate"
                name="resultsCurrentToDate"
                type="text"
                required
                placeholder='Results Current To Date'
              />
 <Button color='primary' outline type='submit'>
                  {loadingSavePostingDate ? (
                    'Saving...'
                  ) : (
                    'Save Changes'
                  )}
                </Button>
                <ToastContainer />             
            </InputGroup>
            </Form>
          </Row>

 
          </CardHeader>
          <CardBody className='event-card-body'>
            {loading ? (
              <div className="loader" >
                <ContentLoader 
                    speed={1}
                    width={'100%'}
                    height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                    viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    {Array.from({ length: 10 }, (_, index) => (
                      <rect
                        key={index}
                        x="0"
                        y={(70 + 4) * index}
                        rx="5"
                        ry="5"
                        width="100%"
                        height="70"
                      />
                    ))}
                  </ContentLoader>
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={postingDatesList}
                theme=""
                fixedHeader
                sortIcon={<FilterListIcon />}
              />
            )}
          </CardBody>
        </Card>
      </div>


    </div>
  );
}

export default PostingDates;
